import { DeleteOutlined } from '@ant-design/icons'
import { message, Modal, Space, Table } from 'antd'
import R from 'assets'
import { ButtonAdd, ButtonDelete, ButtonEdit } from 'common/components/Buttons'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IS_ACTIVE } from 'utils/constants'
import { deleteKeyword } from '../FacebookApi'
import {
  handleDataKeywordAddSource,
  handleDataKeywordManagement,
  handleKeyword,
  handleKeywordSource,
} from '../FacebookSlice'

type Props = {
  show: boolean
  setShowKeywordManagementModal: (show: boolean) => void
  setShowAddSourceModal: (show: boolean) => void
  setShowAddEditKeywordModal: (show: boolean) => void
  setShowEditKeywordSourceModal?: (show: boolean) => void
  getDataKeywordManagement: (paramSearchKeyword: {
    search: string
    page?: number
  }) => any
  prevModal: string
  setPrevModal: (prevModalName: string) => void
}

type ParamSearchKeyword = {
  search: string
  page?: number
}

export default function KeywordManagementModal({
  show,
  setShowKeywordManagementModal,
  setShowAddSourceModal,
  setShowAddEditKeywordModal,
  setShowEditKeywordSourceModal,
  getDataKeywordManagement,
  prevModal,
  setPrevModal,
}: Props) {
  const dispatch = useDispatch()

  const { keywordAddSourceModalSelected } = useSelector(
    (state: any) => state.facebookReducer
  )
  const { keywordSource } = useSelector((state: any) => state.facebookReducer)

  const [
    paramSearchKeyword,
    setParamSearchKeyword,
  ] = useState<ParamSearchKeyword>({
    search: '',
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)

  const [dataTableKeyword, setDataTableKeyword] = useState<any>([])

  const [
    keywordManagementModalSelected,
    setKeywordManagementModalSelected,
  ] = useState<any>([])

  const getData = async () => {
    try {
      setIsSearchLoading(true)
      const response = await getDataKeywordManagement(paramSearchKeyword)
      const dataTable = response.data.map((item: any) => ({
        ...item,
        key: item.id,
      }))
      setDataTableKeyword(dataTable)
      setPaging({
        total: response.paging.totalItemCount,
        current: response.paging.page,
        pageSize: response.paging.limit,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsSearchLoading(false)
    }
  }

  useEffect(() => {
    getData()
    ;(async function () {})()
  }, [show, paramSearchKeyword])

  const columns = [
    {
      title: 'Từ khoá',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (value: number) => (
        <td>
          {value === IS_ACTIVE.ACTIVE
            ? R.strings().active
            : R.strings().un_active}
        </td>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => (
        <td>{moment(value).format('DD-MM-YYYY HH:mm')}</td>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',

      render: (text: any, row: any, index: number) => {
        return (
          <div style={{ textAlign: 'center', cursor: 'pointer' }}>
            <Space size="middle">
              <ButtonEdit
                onClickButton={() => {
                  dispatch(handleKeyword(row))
                  setShowAddEditKeywordModal(true)
                  setShowKeywordManagementModal(false)
                }}
              />

              <ButtonDelete
                //confirm
                title={R.strings().confirm_remove_keyword}
                cancelText={R.strings().action_back}
                okText={R.strings().action_remove}
                onConfirm={async () => {
                  try {
                    await deleteKeyword({
                      keyword_id: row.id,
                    })
                    message.success(`Xoá thành công từ khoá ${row?.content}`)
                  } catch (error) {
                    console.log(error)
                  } finally {
                    getData()
                  }
                }}
                //button
                icon={<DeleteOutlined />}
              />
            </Space>
          </div>
        )
      },
    },
  ]

  const handleCancel = () => {
    setShowKeywordManagementModal(false)
    if (prevModal === 'addSourceModal') {
      setShowAddSourceModal(true)
      setPrevModal('')
    } else {
      setShowEditKeywordSourceModal && setShowEditKeywordSourceModal(true)
      setPrevModal('')
    }
  }

  const handleSubmit = () => {
    console.log(
      'keywordManagementModalSelected:',
      keywordManagementModalSelected
    )
    dispatch(handleDataKeywordManagement(keywordManagementModalSelected))
    const dataKeyword = keywordManagementModalSelected.map((item: any) => ({
      keyword_id: item.id,
      keyword: item.content,
    }))
    dispatch(
      handleKeywordSource({
        id: keywordSource.id,
        keyword: dataKeyword,
      })
    )
    setShowKeywordManagementModal(false)
    if (prevModal === 'addSourceModal') {
      setShowAddSourceModal(true)
      setPrevModal('')
    } else {
      setShowEditKeywordSourceModal && setShowEditKeywordSourceModal(true)
      setPrevModal('')
    }
  }

  const rowSelection = {
    onSelect: (
      record: any,
      selected: boolean,
      selectedRows: Record<string, any>[],
      nativeEvent: any
    ) => {
      if (selected) {
        const newList = [...keywordAddSourceModalSelected, record]
        setKeywordManagementModalSelected(newList)
        dispatch(handleDataKeywordAddSource(newList))
      } else {
        const newList = keywordAddSourceModalSelected.filter(
          (item: any) => item.id !== record.id
        )
        setKeywordManagementModalSelected(newList)
        dispatch(handleDataKeywordAddSource(newList))
      }
    },
    onSelectAll: (selected: boolean, selectedRows: Record<string, any>[]) => {
      if (selected) {
        setKeywordManagementModalSelected(selectedRows)
        dispatch(handleDataKeywordAddSource(selectedRows))
      } else {
        setKeywordManagementModalSelected(selectedRows)
        dispatch(handleDataKeywordAddSource(selectedRows))
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.is_active !== IS_ACTIVE.ACTIVE,
    }),
    preserveSelectedRowKeys: true,
  }

  return (
    <Modal
      title={R.strings().title_modal_keyword_management}
      visible={show}
      //   onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={700}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <div style={{ marginRight: '10px' }}>
          <TypingAutoSearch
            onSearchSubmit={(searchKey: string) => {
              setIsSearchLoading(true)
              setParamSearchKeyword({
                ...paramSearchKeyword,
                search: searchKey.trim(),
                page: 1,
              })
            }}
            isSearchLoading={isSearchLoading}
            placeholder="Nhập từ khoá"
          />
        </div>

        <div>
          <ButtonAdd
            text={R.strings().action_create_keyword}
            onClickButton={() => {
              dispatch(
                handleKeyword({
                  id: 0,
                  content: '',
                  isActive: 0,
                })
              )
              setShowAddEditKeywordModal(true)
              setShowKeywordManagementModal(false)
            }}
          />
        </div>
      </div>

      <div>
        <Table
          dataSource={dataTableKeyword}
          columns={columns}
          loading={isSearchLoading}
          scroll={{
            y: 500,
            scrollToFirstRowOnChange: true,
          }}
          rowSelection={{
            ...rowSelection,
            selectedRowKeys: keywordAddSourceModalSelected.map(
              (item: any) => item.id
            ),
          }}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParamSearchKeyword({ ...paramSearchKeyword, page })
            },
          }}
        />
      </div>

      <div>
        <ButtonBottomModal
          text="Chọn"
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}
