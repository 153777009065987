import { message, Modal, Table } from 'antd'
import R from 'assets'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import { ROUTER_PATH } from 'common/config'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { createSource, getFacebookCategory } from '../FacebookApi'

type Props = {
  show: boolean
  setShowSelectGroupModal: (show: boolean) => void
  setShowAddSourceModal: (show: boolean) => void
}

type ParamFacebookCategory = {
  search: string
}

export default function SelectGroupModal({
  show,
  setShowSelectGroupModal,
  setShowAddSourceModal,
}: Props) {
  const location: any = useLocation()
  const checkedLocation = location.pathname.indexOf('/facebook-detail')
  const history = useHistory()
  const { addSource } = useSelector((state: any) => state.facebookReducer)
  const [selectedRowList, setSelectedRowList] = useState<Array<any>>([])
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [
    paramFacebookCategory,
    setParamFacebookCategory,
  ] = useState<ParamFacebookCategory>({
    search: '',
  })

  const getDataFacebookCategory = async () => {
    try {
      setIsLoading(true)
      const response = await getFacebookCategory(paramFacebookCategory)
      const dataTable = response.data.map((item: any, index: number) => ({
        ...item,
        key: item.group_id,
      }))
      setDataSource(dataTable)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDataFacebookCategory()
  }, [show, paramFacebookCategory])

  const columns = [
    {
      title: 'Tên nhóm',
      dataIndex: 'name',
      key: 'name',
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      const selectedRowID = selectedRows.map((item: any) => {
        return item
      })
      setSelectedRowList(selectedRowID)
    },
    preserveSelectedRowKeys: true,
  }

  const handleCancel = () => {
    setParamFacebookCategory({ ...paramFacebookCategory, search: '' })
    setShowSelectGroupModal(false)
    setShowAddSourceModal(true)
    setSelectedRowList([])
  }

  const handleSubmit = async () => {
    if (selectedRowList.length < 1)
      return message.warn('Vui lòng chọn nhóm để tiếp tục')
    const list_group_category = selectedRowList.map((item: any) => ({
      name: item.name,
      group_id: item.group_id,
    }))
    const valueCreateSource = { ...addSource, list_group_category }
    delete valueCreateSource.links
    setIsLoadingButton(true)
    try {
      await createSource(valueCreateSource)
      if (checkedLocation === -1) {
        history.push(`${ROUTER_PATH.FACEBOOK_DETAIL}/${addSource.group_id}`)
        message.success('Tạo chiến dịch thành công')
      }
      message.success('Thiết lập nguồn thành công')
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingButton(false)
      setShowSelectGroupModal(false)
    }
  }

  return (
    <Modal
      title={R.strings().title_modal_select_group}
      visible={show}
      //   onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={700}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <div style={{ marginRight: '10px', flex: 1 }}>
          <TypingAutoSearch
            onSearchSubmit={(searchKey: string) => {
              setParamFacebookCategory({
                ...paramFacebookCategory,
                search: searchKey.trim(),
              })
            }}
            isSearchLoading={isLoading}
            placeholder="Nhập từ khoá"
          />
        </div>

        <div style={{ fontSize: '16px', alignSelf: 'center' }}>
          Đã chọn: {selectedRowList.length}
        </div>
      </div>

      <div>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowSelection={{ ...rowSelection }}
          scroll={{ scrollToFirstRowOnChange: true, y: '400px' }}
          loading={isLoading}
          pagination={{
            pageSize: 20,
            hideOnSinglePage: true,
          }}
        />
      </div>

      <div style={{ marginTop: '10px' }}>
        <ButtonBottomModal
          text="Lưu"
          isLoadingButton={isLoadingButton}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}
