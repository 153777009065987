import { MinusCircleOutlined } from '@ant-design/icons'
import { Modal, Form, Input, Space, Row, Spin } from 'antd'
import R from 'assets'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { useState } from 'react'
import { facebookStartWithHTTPS } from 'utils/regexs'
import { convertfb } from '../FacebookApi'

type Props = {
  show: boolean
  setConvertedFBList: (list: Record<string, any>) => void
  setShowConvertFacebookModal: (show: boolean) => void
  setShowResultConvertFacebookModal: (show: boolean) => void
}

export default function ConvertFacebookModal({
  show,
  setShowConvertFacebookModal,
  setShowResultConvertFacebookModal,
  setConvertedFBList,
}: Props) {
  const handleCancel = () => {
    setShowConvertFacebookModal(false)
  }
  const [loading, setLoading] = useState<boolean>(false)
  const onFinish = async (links: Record<'links', string[]>) => {
    setLoading(true)
    try {
      const convertFBresponse = await convertfb(links)
      setShowConvertFacebookModal(false)
      // For render purpose only
      const FBResponseAddedKey = convertFBresponse.data.map(
        (fb: any, index: number) => ({ ...fb, key: index })
      )
      setConvertedFBList({
        success: FBResponseAddedKey,
        total: links.links,
      })
      setShowResultConvertFacebookModal(true)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        title={R.strings().title_modal_convert_facebook}
        visible={show}
        onCancel={handleCancel}
        footer={null}
      >
        <Spin spinning={loading}>
          <p>Nhập trang facebook người dùng</p>
          <Form name="dynamic_form_item" onFinish={onFinish}>
            <Form.List name="links" initialValue={['']}>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Vui lòng nhập trang facebook !',
                          },
                          {
                            pattern: facebookStartWithHTTPS,
                            message:
                              'Vui lòng nhập đúng định dạng https://www.facebook.com/example !',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Nhập"
                          style={{ width: fields.length > 1 ? '91%' : '100%' }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          style={{ color: '#8c8c8c' }}
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  {fields.length < 10 ? (
                    <Form.Item>
                      <Space>
                        <span>Thêm bài viết</span>
                        <span
                          onClick={() => add()}
                          style={{
                            fontSize: '20px',
                            borderRadius: '50%',
                            backgroundColor: '#7cb305',
                            fontWeight: 'bold',
                            padding: '3px 10px',
                            color: '#fff',
                            cursor: 'pointer',
                          }}
                        >
                          +
                        </span>
                      </Space>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  ) : null}
                </>
              )}
            </Form.List>
            <Row justify="end">
              <Form.Item>
                <ButtonBottomModal
                  text="Convert"
                  onCancel={() => handleCancel()}
                />
              </Form.Item>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}
