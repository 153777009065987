import { ROUTER_PATH } from './../common/config/router'
import { Facebook } from '../features/customer-resources'
import { Google } from '../features/customer-resources'
import { BusinessRegistration } from '../features/customer-resources'
import SetOfCustomer from '../features/set-of-customers'
import React from 'react'
import { AnyNaptrRecord } from 'dns'
import { FacebookDetail } from 'features/customer-resources'
import { CreateCampaign } from 'features/customer-resources'
import SetOfCustomerDetail from 'features/set-of-customers/SetOfCustomerDetail'

interface RouterProps {
  path: string
  component: React.FC | React.Component | any
  param?: AnyNaptrRecord
}

const Router: Array<RouterProps> = [
  {
    path: ROUTER_PATH.HOME,
    component: Facebook,
  },
  {
    path: ROUTER_PATH.FACEBOOK,
    component: Facebook,
  },
  {
    path: ROUTER_PATH.FACEBOOK_DETAIL + '/:id',
    component: FacebookDetail,
  },
  {
    path: ROUTER_PATH.CREATE_CAMPAIGN,
    component: CreateCampaign,
  },
  {
    path: ROUTER_PATH.GOOGLE,
    component: Google,
  },
  {
    path: ROUTER_PATH.BUSINESS_REGISTRATION,
    component: BusinessRegistration,
  },
  {
    path: ROUTER_PATH.SET_OF_CUSTOMER,
    component: SetOfCustomer,
  },
  {
    path: ROUTER_PATH.SET_OF_CUSTOMER_DETAIL + '/:id',
    component: SetOfCustomerDetail,
  },
]

export default Router
