import io, { Socket } from 'socket.io-client'
import Cookie from 'js-cookie'
import { ENVIRONMENT, SESSION_KEY } from 'common/config'

export class WebSocket {
  public static socketClient: Socket
  private static currentToken: string | undefined

  public static async init(
    token: string | undefined,
    userId: number | undefined
  ) {
    if (!this.socketClient && this.currentToken !== token && userId) {
      this.currentToken = token
      this.socketClient = io(`${ENVIRONMENT.WS_URL}enterprise/${userId}`, {
        auth: { token: Cookie.get(SESSION_KEY.SESSION) },
        path: '/socket.io',
        transports: ['websocket'],
        secure: true,
      })
    }
  }
  public static async disconnect() {
    if (this.socketClient) {
      this.socketClient.disconnect()
    }
  }
}
