import { Layout } from 'antd'
import React from 'react'
import PrivateRoute from './PrivateRoute'
import screenRouter from './routerType'
export default function Nav() {
  return (
    <>
      <Layout style={{ paddingTop: 10 }}>
        {screenRouter.map((val, index) => (
          <PrivateRoute
            key={index}
            path={`${val.path}`}
            component={val.component}
            exact
          />
        ))}
      </Layout>
    </>
  )
}
