import React, { Fragment } from 'react'
import { Tooltip } from 'antd'

import { DATA_CRAWL_STATUS } from 'utils/constants'
import {
  ItemDataCrawStatusStyle,
  failColor,
  successColor,
  pendingColor,
} from 'features/styles/Styles'

import R from 'assets'

interface IRenderTooltip {
  title: string
  color: string
  key: number
}

interface IRenderItemDataCrawStatus {
  statusData: number[]
}

const RenderTooltip = ({ title, color, key }: IRenderTooltip) => (
  <Tooltip title={title} color={color} key={key}>
    <ItemDataCrawStatusStyle bgColor={color} />
  </Tooltip>
)

export default function RenderItemDataCrawStatus({
  statusData,
}: IRenderItemDataCrawStatus) {
  return (
    <Fragment>
      {statusData.map((s: number, index: number) => {
        let title = R.strings().define_status_pending
        let color = pendingColor

        switch (s) {
          case DATA_CRAWL_STATUS.SUCCESS:
            title = R.strings().define_status_success
            color = successColor
            break
          case DATA_CRAWL_STATUS.FAILED:
            title = R.strings().define_status_failed
            color = failColor
            break
        }

        return <RenderTooltip title={title} color={color} key={index} />
      })}
    </Fragment>
  )
}
