import { IS_ACTIVE } from 'utils/constants'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  addSource: {
    type_group: 0,
    // branch: '',
    // branchSelected: {},
    keywords: [],
    links: [],
    group_id: 0,
  },

  //sửa keyword
  keyword: {
    id: 0,
    content: '',
    isActive: 0,
  },

  //sửa từ khoá nguồn
  keywordSource: {
    //id nguồn
    id: 0,
    //list keyword của nguồn đó
    keyword: [],
  },

  //keyword selected trong keywordManagementModal
  keywordManagementModalSelected: [],

  //keyword selected trong addSourceModal
  keywordAddSourceModalSelected: [],
}

export const facebookSlice = createSlice({
  name: 'facebook',
  initialState,
  reducers: {
    handleAddSource: (state, action) => {
      console.log(action)
      state.addSource.type_group = action.payload.type_group
      // state.addSource.branch = action.payload.branch
      // state.addSource.branchSelected = action.payload.branchSelected
      state.addSource.keywords = action.payload.keywords
      state.addSource.links = action.payload.links
      state.addSource.group_id = parseInt(action.payload.group_id)
    },
    //xử lí những từ khoá được chọn ở keywordManagementModal
    handleDataKeywordManagement: (state, action) => {
      console.log(action)
      state.keywordManagementModalSelected = action.payload
    },
    //xử lí những keyword được chọn ở addSourceModal
    handleDataKeywordAddSource: (state, action) => {
      console.log(action.payload)
      const data = action.payload.map((item: any) => ({
        id: item.keyword_id || item.id,
        key: item.keyword_id || item.id,
        content: item.keyword || item.content,
        is_active: IS_ACTIVE.ACTIVE,
      }))
      state.keywordAddSourceModalSelected = data
    },
    //sửa keyword
    handleKeyword: (state, action) => {
      state.keyword.id = action.payload.id
      state.keyword.content = action.payload.content
      state.keyword.isActive = action.payload.is_active
    },
    //sửa từ khoá nguồn
    handleKeywordSource: (state, action) => {
      console.log(action.payload)
      state.keywordSource.id = action.payload.id
      state.keywordSource.keyword = action.payload.keyword
      // console.log(action.payload)
    },
  },
})

export const {
  handleAddSource,
  handleDataKeywordManagement,
  handleKeyword,
  handleKeywordSource,
  handleDataKeywordAddSource,
} = facebookSlice.actions

export default facebookSlice.reducer
