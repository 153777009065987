import { ApiClient } from 'services/ApiService'

interface PayloadLogin {
  user_name: string
  password: string
}

export const requestLogin = (payload: PayloadLogin) =>
  ApiClient.post('/user/login', payload)
export const requestLogout = () => ApiClient.post('/user/logout')
export const requestGetUserInfo = () => ApiClient.get('/user/userInfo')
