export const colors = {
  bgCard: '#eff4ff',
  primary: '#ffffff',
  backgroundColor: '#E5E5E5',
  white: '#ffffff',
  black: '#000000',
  focus: '#707070',
  orange: '#fe7a15',
  colorDefault: {
    primary: 'rgb(0, 122, 255)',
    background: 'rgb(242, 242, 242)',
    card: 'rgb(255, 255, 255)',
    text: 'rgb(28, 28, 30)',
    border: 'rgb(216, 216, 216)',
    notification: 'rgb(255, 59, 48)',
    error: 'rgb(255, 59, 48)',
    info: '#ffd700',
  },
  purple: {
    darker: '#1B0036',
    dark: '#300062',
    primary: '#602D90',
    light: '#915AC1',
    lighter: '#F1E0FF',
  },
  pink: {
    darker: '#943D4C',
    dark: '#C06776',
    primary: '#F596A5',
    light: '#FFC8D6',
    lighter: '#FFE5EB',
  },
  success: {
    darker: '#237804',
    dark: '#389E0D',
    primary: '#52C41A',
    light: '#B7EB8F',
  },
  warning: {
    darker: '#AD6800',
    dark: '#D48806',
    primary: '#FAAD14',
    light: '#FFE58F',
  },
  error: {
    darker: '#A8071A',
    dark: '#CF1322',
    primary: '#FF4D4F',
    light: '#FFA39E',
  },
  text: {
    primary: '#212B36',
    light: '#454F5B',
    lighter: '#637381',
    lightest: '#919EAB',
  },
}

export const ColorDefault = {
  primary: 'rgb(0, 122, 255)',
  background: 'rgb(242, 242, 242)',
  card: 'rgb(255, 255, 255)',
  text: 'rgb(28, 28, 30)',
  border: 'rgb(216, 216, 216)',
  notification: 'rgb(255, 59, 48)',
  error: 'rgb(255, 59, 48)',
  info: '#ffd700',
}
export const ColorDark = {
  primary: 'rgb(10, 132, 255)',
  background: 'rgb(1, 1, 1)',
  card: 'rgb(18, 18, 18)',
  text: 'rgb(229, 229, 231)',
  border: 'rgb(39, 39, 41)',
  notification: 'rgb(255, 69, 58)',
  error: 'rgb(255, 59, 48)',
  info: '#ffd700',
}
