import R from 'assets'

export const RESTART_ON_REMOUNT = '@saga-injector/restart-on-remount'

export const API_STATUS = {
  RE_LOGIN: 403,
  NOT_FOUND: 404,
}

export const REQUEST_STATUS = {
  PENDING: 0,
  SUCCESS: 1,
  REFUSE: 2,
}

export const REG_PHONE = /(84|0[3|5|7|8|9])+([0-9]{8})\b/

export const IS_ACTIVE = {
  ACTIVE: 1,
  DEACTIVE: 2,
  IN_ACTIVE: 0,
}

export const TYPE_SOURCE_FACEBOOK = {
  GROUP: 1,
  PAGE: 2,
  POST: 3,
  ADVERTISEMENT: 4,
}

export const CONFIG = {
  CRYPT_SALT: 10,
  PAGING_LIMIT: 12,
  URL_GET_ID_FB: 'https://ahachat.com',
  FB_URL: 'https://www.facebook.com/',
}

export const GROUP_TYPE = {
  FACEBOOK: 1,
  GOOGLE: 2,
  DKKD: 3,
}

export const SELECT_MEMBER_TYPE = {
  CUSTOMER: 1,
  POST: 2,
  ADS: 3,
}

export const DF_SELECT_MEMBER = {
  [SELECT_MEMBER_TYPE.CUSTOMER]: {
    id: SELECT_MEMBER_TYPE.CUSTOMER,
    title: R.strings().filter_member_customer,
  },
  [SELECT_MEMBER_TYPE.POST]: {
    id: SELECT_MEMBER_TYPE.POST,
    title: R.strings().filter_member_post,
  },
  // [SELECT_MEMBER_TYPE.ADS]: {
  //   id: SELECT_MEMBER_TYPE.ADS,
  //   title: R.strings().filter_member_ads,
  // },
}

export const optionSelect = [
  {
    value: SELECT_MEMBER_TYPE.CUSTOMER,
    label: R.strings().filter_member_customer,
  },
  {
    value: SELECT_MEMBER_TYPE.POST,
    label: R.strings().filter_member_post,
  },
  // {
  //   value: SELECT_MEMBER_TYPE.ADS,
  //   label: R.strings().filter_member_ads,
  // },
]

export const DATA_CRAWL_STATUS = {
  SUCCESS: 3,
  FAILED: 4,
}

export const OPTION_SELECT_CUSTOMER_GROUP = {
  GROUP_EXISTS: 1,
  CREATE_NEW_CUSTOMER_GROUP: 2,
}

export const LIST_CUSTOMER_TYPE = {
  TEAM_MEMBERS: 1, // THÀNH VIÊN NHÓM
  MEMBERS_COMMENT_ARTICLE: 2, // THÀNH VIÊN COMMENT BÀI VIẾT
}
