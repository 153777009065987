import { Tooltip } from 'antd'
import { TooltipType } from './Tooltip.props'
import styled from 'styled-components'
import { NumberLine } from 'features/styles/Styles'

const ContentOneLine = styled.span`
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  line-height: 2.6rem;
`

const ScrollContent = styled.div`
  width: 150px;
  height: 100px;
  overflow: scroll;
  float: 'center';
`

export const TooltipCustom = ({
  title,
  content,
  children,
  ...props
}: TooltipType) => {
  return (
    <Tooltip mouseLeaveDelay={0.2} title={title}>
      {children}
      <NumberLine>{content}</NumberLine>
    </Tooltip>
  )
}
