import { Select } from 'antd'
import './Input.css'

const { Option } = Select

export interface OptionItem {
  value: string | number
  text: string | number
}

export interface PropsRadiusSelection {
  placeholder: string
  defaultValue?: number | string
  options: Array<OptionItem>
  onChange: (value: any) => void
  onFocus?: () => void
  onBlur?: () => void
  showSearch?: boolean
  onSearch?: () => void
  style?: any
}

export default function RadiusSelection({
  placeholder,
  defaultValue,
  options,
  onChange,
  onFocus,
  onBlur,
  showSearch,
  onSearch,
  style,
}: PropsRadiusSelection) {
  return (
    <Select
      className="radius-select"
      style={style ? style : { width: '100%' }}
      allowClear
      placeholder={placeholder}
      defaultValue={defaultValue || undefined}
      optionFilterProp="children"
      showSearch={showSearch}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
    >
      {options.map((item: OptionItem) => (
        <Option value={item.value}>{item.text}</Option>
      ))}
    </Select>
  )
}
