import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'

type ButtonFixedProps = {
  onClickButton?: () => void
  text?: string
  icon?: any
  loading?: boolean
}

const ButtonEdit = ({ text, onClickButton, icon }: ButtonFixedProps) => {
  return (
    <Button
      style={{
        minWidth: '45px',
        fontWeight: 'bold',
        borderRadius: '5px',
      }}
      type={text ? 'primary' : 'text'}
      onClick={() => {
        onClickButton && onClickButton()
      }}
    >
      {icon ? icon : <EditOutlined />}
      {text}
    </Button>
  )
}

export default ButtonEdit
