import { Form, message, Modal, Select } from 'antd'
import R from 'assets'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IS_ACTIVE } from 'utils/constants'
import {
  handleKeywordSource,
  handleDataKeywordAddSource,
  handleDataKeywordManagement,
} from '../FacebookSlice'
import { updateKeywordSource } from '../FacebookApi'
import _ from 'lodash'
import ButtonSetting from 'common/components/Buttons/ButtonSetting'

const { Option } = Select

type Props = {
  show: boolean
  setShowEditKeywordSourceModal?: (show: boolean) => void
  setShowKeywordManagementModal?: (show: boolean) => void
  getDataKeywordManagement: (paramSearchKeyword: {
    search: string
    page?: number
  }) => any
  setPrevModal: (prevModalName: string) => void
}

type DataKeyword = {
  content: string
  created_at?: string
  id: number
  is_active?: number
  user_id?: number
}

export default function EditKeywordSourceModal({
  show,
  setShowEditKeywordSourceModal,
  setShowKeywordManagementModal,
  getDataKeywordManagement,
  setPrevModal,
}: Props) {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [postFilterKeywords, setPostFilterKeywords] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCancel = () => {
    setShowEditKeywordSourceModal && setShowEditKeywordSourceModal(false)
    form.resetFields()
    dispatch(
      handleKeywordSource({
        id: 0,
        keyword: [],
      })
    )
    dispatch(handleDataKeywordManagement([]))
  }

  const { keywordSource, keywordManagementModalSelected } = useSelector(
    (state: any) => state.facebookReducer
  )

  //start keyword logic
  const [dataKeyword, setDataKeyword] = useState<Array<DataKeyword>>([])
  useEffect(() => {
    ;(async function () {
      try {
        //lấy keyword từ quản lí từ khoá (API)
        const response = await getDataKeywordManagement({
          search: '',
          // page: 1,
        })
        // console.log('response: ', response)
        //lấy keyword từ nguồn
        const keyword = keywordSource.keyword.map((item: any) => ({
          id: item.keyword_id,
          content: item.keyword,
          is_active: 1,
        }))
        // console.log('lấy từ nguồn: ', keyword)
        //nối keyword lấy từ nguồn và keyword từ API
        const newList = [...keyword, ...response.data]
        //lọc trùng theo id lấy ra keyword mới nhất theo lodash
        const dataKeywordNew = _.uniqBy(newList, 'id')
        // console.log('data option: ', dataKeywordNew)
        setDataKeyword(dataKeywordNew)
        //đặt giá trị ban đầu cho form
        // console.log(
        //   'value select form: ',
        //   keywordSource.keyword.map((item: any) => item.keyword_id)
        // )
        form.setFieldsValue({
          keyword: keywordSource.keyword.map((item: any) => item.keyword_id),
        })
        setPostFilterKeywords(keywordSource.keyword)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [show])
  //end keyword logic

  const onFinish = async (value: any) => {
    console.log(postFilterKeywords)
    try {
      setIsLoading(true)
      await updateKeywordSource({
        sub_group_id: keywordSource.id,
        list_keyword: postFilterKeywords,
      })
      message.success('Cập nhật từ khoá thành công')
      dispatch(handleDataKeywordManagement([]))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      setShowEditKeywordSourceModal && setShowEditKeywordSourceModal(false)
    }
  }

  return (
    <Modal
      title={R.strings().title_modal_edit_keyword}
      visible={show}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="add_keyword"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item label="Chọn từ khoá">
          <div style={{ display: 'flex' }}>
            <Form.Item name="keyword" style={{ width: '90%' }}>
              <Select
                placeholder="Chọn từ khoá"
                mode="multiple"
                onChange={(value: any, option: any) => {
                  // console.log('option:', option)
                  // const responseConvert = value.map((item: any) => JSON.parse(item))
                  const response = option.map((item: any) => ({
                    keyword_id: item.value,
                    keyword: item.children,
                  }))
                  setPostFilterKeywords(response)
                }}
                optionFilterProp={'children'}
                filterOption={true}
              >
                {_.uniqBy(
                  [...dataKeyword, ...keywordManagementModalSelected],
                  'id'
                ).map((item: DataKeyword) => {
                  if (item.is_active === IS_ACTIVE.ACTIVE) {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.content}
                      </Option>
                    )
                  }
                })}
              </Select>
            </Form.Item>

            <ButtonSetting
              // text="Quản lý từ khoá"
              onClickButton={() => {
                setPrevModal('editKeywordSourceModal')
                dispatch(handleDataKeywordAddSource(postFilterKeywords))
                setShowEditKeywordSourceModal &&
                  setShowEditKeywordSourceModal(false)
                setShowKeywordManagementModal &&
                  setShowKeywordManagementModal(true)
              }}
            />
          </div>
        </Form.Item>

        <ButtonBottomModal
          text="Lưu"
          onCancel={handleCancel}
          isLoadingButton={isLoading}
        />
      </Form>
    </Modal>
  )
}
