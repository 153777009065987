import R from 'assets'
import { ButtonAdd } from 'common/components/Buttons'
import { TypingAutoSearchProps } from 'common/components/Inputs'
import { ROUTER_PATH } from 'common/config'
import React from 'react'
import { useHistory } from 'react-router'

type Props = {
  isSearchLoading: boolean
  onSearchSubmit: (searchKey: string) => void
}

export default function Filter({ isSearchLoading, onSearchSubmit }: Props) {
  const history = useHistory()
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ minWidth: '400px' }}>
        <TypingAutoSearchProps
          onSearchSubmit={(searchKey: string) => {
            onSearchSubmit(searchKey.trim())
          }}
          isSearchLoading={isSearchLoading}
          placeholder={R.strings().search_campaign_name}
        />
      </div>

      <div>
        <ButtonAdd
          text={R.strings().action_create_campaign}
          onClickButton={() => history.push(ROUTER_PATH.CREATE_CAMPAIGN)}
        />
      </div>
    </div>
  )
}
