import { MinusCircleOutlined } from '@ant-design/icons'
import { Form, Input, InputNumber, message, Modal, Select, Space } from 'antd'
import R from 'assets'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import ButtonSetting from 'common/components/Buttons/ButtonSetting'
import { ROUTER_PATH } from 'common/config'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { IS_ACTIVE, TYPE_SOURCE_FACEBOOK } from 'utils/constants'
import { facebookStartWithHTTPS } from 'utils/regexs'
import { createSource } from '../FacebookApi'
import {
  handleAddSource,
  handleDataKeywordAddSource,
  handleDataKeywordManagement,
} from '../FacebookSlice'

const { Option } = Select

const InputNumberStyle = styled(InputNumber)`
  width: 100%;
`

const DEFAULT_SELECT_COMMENT_AND_REACTION = 1

const FACEBOOK_SOURCE_TYPE_WITH_GROUP = {
  EMPTY: 0,
  FILTER_BY_GROUP: 1,
  ENTER_GROUP_PATH: 2,
}

type Props = {
  groupId?: number
  show: boolean
  setShowAddSourceModal: (show: boolean) => void
  setShowKeywordManagementModal: (show: boolean) => void
  setShowSelectGroupModal: (show: boolean) => void
  getDataFacebookBranch: (limit?: number) => any
  getDataKeywordManagement: (paramSearchKeyword: {
    search: string
    page?: number
  }) => any
  setPrevModal: (prevModalName: string) => void
}

type ParamSearchKeyword = {
  search: string
  page?: number
}

type DataKeyword = {
  content: string
  created_at: string
  id: number
  is_active: number
  user_id: number
}

export default function AddSourceModal({
  groupId,
  show,
  setShowAddSourceModal,
  setShowKeywordManagementModal,
  setShowSelectGroupModal,
  getDataFacebookBranch,
  getDataKeywordManagement,
  setPrevModal,
}: Props) {
  const history = useHistory()
  const location: any = useLocation()
  const [form] = Form.useForm()
  const checkedLocation = location.pathname.indexOf(ROUTER_PATH.FACEBOOK_DETAIL)
  const dispatch = useDispatch()
  const { keywordManagementModalSelected } = useSelector(
    (state: any) => state.facebookReducer
  )
  const [sourceFacebook, setSourceFacebook] = useState<number>(0)
  const [
    facebookSourceTypeWithGroup,
    setFacebookSourceTypeWithGroup,
  ] = useState<number>(FACEBOOK_SOURCE_TYPE_WITH_GROUP.EMPTY)
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)
  const [postFilterKeywords, setPostFilterKeywords] = useState<any>([])

  //xử lí keyword chọn từ KeywordManagementModal và truyền xuống select từ khoá
  useEffect(() => {
    form.setFieldsValue({
      post_filter_keywords: keywordManagementModalSelected.map(
        (item: any) => item.id
      ),
      keywords: keywordManagementModalSelected.map((item: any) => item.id),
    })
    const res = keywordManagementModalSelected.map((item: any) => ({
      keyword_id: item.id,
      keyword: item.content,
    }))
    setPostFilterKeywords(res)
  }, [keywordManagementModalSelected])
  //kết thúc xử lí keyword chọn từ KeywordManagementModal và truyền xuống select từ khoá

  //start facebook branch logic
  const [dataFacebookBranch, setDataFacebookBranch] = useState<Array<any>>([])
  useEffect(() => {
    ;(async function () {
      try {
        const response = await getDataFacebookBranch()
        setDataFacebookBranch(response.data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [show])
  //end facebook branch logic

  //start keyword logic
  const [
    paramSearchKeyword,
    setParamSearchKeyword,
  ] = useState<ParamSearchKeyword>({
    search: '',
    page: 1,
  })
  const [dataKeyword, setDataKeyword] = useState<Array<DataKeyword>>([])
  useEffect(() => {
    ;(async function () {
      try {
        const response = await getDataKeywordManagement(paramSearchKeyword)
        const data = response.data.map((item: any) => ({
          ...item,
          key: item.id,
        }))
        setDataKeyword(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [show, paramSearchKeyword])
  //end keyword logic

  const handleCancel = () => {
    setShowAddSourceModal(false)
    form.resetFields()
    dispatch(handleDataKeywordManagement([]))
    setSourceFacebook(0)
    setFacebookSourceTypeWithGroup(FACEBOOK_SOURCE_TYPE_WITH_GROUP.EMPTY)
    setPostFilterKeywords([])
  }

  const contentSourceFacebook = () => {
    switch (sourceFacebook) {
      case TYPE_SOURCE_FACEBOOK.GROUP:
        return (
          <>
            <Form.Item name="select_type">
              <Select
                placeholder="Chọn kiểu"
                onChange={(value: number) => {
                  setFacebookSourceTypeWithGroup(value)
                }}
              >
                <Option value={FACEBOOK_SOURCE_TYPE_WITH_GROUP.FILTER_BY_GROUP}>
                  {R.strings().options_filter_by_group}
                </Option>
                <Option
                  value={FACEBOOK_SOURCE_TYPE_WITH_GROUP.ENTER_GROUP_PATH}
                >
                  {R.strings().options_enter_group_path}
                </Option>
              </Select>
            </Form.Item>

            {facebookSourceTypeWithGroup ===
            FACEBOOK_SOURCE_TYPE_WITH_GROUP.FILTER_BY_GROUP ? (
              <>
                {/* <Form.Item
                  label="Chọn lĩnh vục"
                  name="branch"
                  // rules={[
                  //   { required: true, message: 'Vui lòng chọn lĩnh vực!' },
                  // ]}
                >
                  <Select
                    placeholder="Chọn lĩnh vực"
                    onChange={(value: any, option: any) =>
                      setBranchSelected(option)
                    }
                  >
                    {dataFacebookBranch.map((item: string, index: number) => (
                      <Option key={index + 1} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item> */}

                <Form.Item label="Chọn từ khoá lọc bài viết">
                  <div style={{ display: 'flex' }}>
                    <Form.Item name="keywords" style={{ width: '90%' }}>
                      <Select
                        placeholder="Chọn từ khoá"
                        mode="multiple"
                        onChange={(value: any, option: any) => {
                          const response = option.map((item: any) => ({
                            keyword_id: item.value,
                            keyword: item.children,
                          }))
                          setPostFilterKeywords(response)
                        }}
                      >
                        {_.uniqBy(
                          [...dataKeyword, ...keywordManagementModalSelected],
                          'id'
                        ).map((item: DataKeyword) => {
                          if (item.is_active === IS_ACTIVE.ACTIVE) {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item.content}
                              </Option>
                            )
                          }
                        })}
                      </Select>
                    </Form.Item>

                    <ButtonSetting
                      onClickButton={() => {
                        setPrevModal('addSourceModal')
                        dispatch(handleDataKeywordAddSource(postFilterKeywords))
                        setShowKeywordManagementModal(true)
                        setShowAddSourceModal(false)
                      }}
                    />
                  </div>
                </Form.Item>
              </>
            ) : facebookSourceTypeWithGroup ===
              FACEBOOK_SOURCE_TYPE_WITH_GROUP.ENTER_GROUP_PATH ? (
              <>
                <p>Nhập đường dẫn nhóm</p>
                <Form.List name="links" initialValue={['']}>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          // label={index === 0 && 'Nhập đường dẫn nhóm'}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Vui lòng nhập đường dẫn nhóm!',
                              },
                              {
                                pattern: facebookStartWithHTTPS,
                                message:
                                  'Vui lòng nhập đúng định dạng https://www.facebook.com/example !',
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="Nhập đường dẫn nhóm"
                              style={{
                                width: fields.length > 1 ? '91%' : '100%',
                              }}
                            />
                          </Form.Item>
                          {fields?.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      {fields?.length < 10 && (
                        <Form.Item>
                          <Space>
                            <span>Thêm nhóm</span>
                            <span
                              onClick={() => add()}
                              style={{
                                fontSize: '20px',
                                borderRadius: '50%',
                                backgroundColor: '#7cb305',
                                fontWeight: 'bold',
                                padding: '3px 10px',
                                color: '#fff',
                                cursor: 'pointer',
                              }}
                            >
                              +
                            </span>
                          </Space>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.List>
              </>
            ) : (
              <></>
            )}
            {/* <Checkbox
              style={{ marginBottom: 10 }}
              checked={isGetMemberGroup}
              onChange={handleTackMemberGroup}
            >
              {R.strings().just_take_member_group}
            </Checkbox> */}
          </>
        )

      case TYPE_SOURCE_FACEBOOK.PAGE:
        return (
          <>
            <p>Nhập đường dẫn trang</p>
            <Form.List
              name="links"
              initialValue={['']}
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('Vui lòng nhập ít nhất 1 đường dẫn trang!')
                      )
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Vui lòng nhập đường dẫn trang!',
                          },
                          {
                            pattern: facebookStartWithHTTPS,
                            message:
                              'Vui lòng nhập đúng định dạng https://www.facebook.com/example !',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Nhập đường dẫn trang"
                          style={{ width: fields.length > 1 ? '91%' : '100%' }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  {fields.length < 10 && (
                    <Form.Item>
                      <Space>
                        <span>Thêm trang</span>
                        <span
                          onClick={() => add()}
                          style={{
                            fontSize: '20px',
                            borderRadius: '50%',
                            backgroundColor: '#7cb305',
                            fontWeight: 'bold',
                            padding: '3px 10px',
                            color: '#fff',
                            cursor: 'pointer',
                          }}
                        >
                          +
                        </span>
                      </Space>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>

            <Form.Item label="Chọn từ khoá lọc bài viết">
              <div style={{ display: 'flex' }}>
                <Form.Item name="post_filter_keywords" style={{ width: '90%' }}>
                  <Select
                    placeholder="Chọn từ khoá"
                    mode="multiple"
                    onChange={(value: any, option: any) => {
                      const response = option.map((item: any) => ({
                        keyword_id: item.value,
                        keyword: item.children,
                      }))
                      setPostFilterKeywords(response)
                    }}
                  >
                    {_.uniqBy(
                      [...dataKeyword, ...keywordManagementModalSelected],
                      'id'
                    ).map((item: DataKeyword) => {
                      if (item.is_active === IS_ACTIVE.ACTIVE) {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.content}
                          </Option>
                        )
                      }
                    })}
                  </Select>
                </Form.Item>

                <ButtonSetting
                  onClickButton={() => {
                    setPrevModal('addSourceModal')
                    dispatch(handleDataKeywordAddSource(postFilterKeywords))
                    setShowKeywordManagementModal(true)
                    setShowAddSourceModal(false)
                  }}
                />
              </div>
            </Form.Item>
          </>
        )

      case TYPE_SOURCE_FACEBOOK.POST:
        return (
          <>
            <p>Nhập đường dẫn bài viết</p>
            <Form.List
              name="links"
              initialValue={['']}
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('Vui lòng nhập ít nhất 1 đường dẫn bài viết!')
                      )
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Vui lòng nhập đường dẫn bài viết!',
                          },
                          {
                            pattern: facebookStartWithHTTPS,
                            message:
                              'Vui lòng nhập đúng định dạng https://www.facebook.com/example !',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Nhập đường dẫn bài viết"
                          style={{ width: fields.length > 1 ? '91%' : '100%' }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  {fields.length < 10 && (
                    <Form.Item>
                      <Space>
                        <span>Thêm bài viết</span>
                        <span
                          onClick={() => add()}
                          style={{
                            fontSize: '20px',
                            borderRadius: '50%',
                            backgroundColor: '#7cb305',
                            fontWeight: 'bold',
                            padding: '3px 10px',
                            color: '#fff',
                            cursor: 'pointer',
                          }}
                        >
                          +
                        </span>
                      </Space>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </>
        )

      case TYPE_SOURCE_FACEBOOK.ADVERTISEMENT:
        return (
          <>
            <p>Nhập đường dẫn bài quảng cáo</p>
            <Form.List
              name="links"
              initialValue={['']}
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('Vui lòng nhập ít nhất 1 đường dẫn bài viết!')
                      )
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Vui lòng nhập đường dẫn bài viết!',
                          },
                          {
                            pattern: facebookStartWithHTTPS,
                            message:
                              'Vui lòng nhập đúng định dạng https://www.facebook.com/example !',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Nhập đường dẫn bài viết"
                          style={{ width: fields.length > 1 ? '91%' : '100%' }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  {fields.length < 10 && (
                    <Form.Item>
                      <Space>
                        <span>Thêm bài viết</span>
                        <span
                          onClick={() => add()}
                          style={{
                            fontSize: '20px',
                            borderRadius: '50%',
                            backgroundColor: '#7cb305',
                            fontWeight: 'bold',
                            padding: '3px 10px',
                            color: '#fff',
                            cursor: 'pointer',
                          }}
                        >
                          +
                        </span>
                      </Space>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </>
        )

      default:
        break
    }
  }

  const onFinish = async (values: any) => {
    let newValue = {}
    if (!values.type_group) message.warn('Vui lòng chọn nguồn')
    switch (values?.type_group) {
      case TYPE_SOURCE_FACEBOOK.GROUP:
        if (
          facebookSourceTypeWithGroup ===
          FACEBOOK_SOURCE_TYPE_WITH_GROUP.FILTER_BY_GROUP
        ) {
          const dataKeyword = postFilterKeywords.map((item: any) => ({
            keyword_id: item.keyword_id,
            keyword: item.keyword,
          }))
          delete values.select_type
          newValue = {
            ...values,
            // branchSelected,

            keywords: dataKeyword,
            group_id: groupId,
          }
          dispatch(handleAddSource(newValue))
          setShowSelectGroupModal(true)
          setShowAddSourceModal(false)
          // form.resetFields()
        } else {
          newValue = {
            ...values,
            group_id: groupId,
            // status_member_df: isGetMemberGroup ? 1 : 0,
            status_member_df: DEFAULT_SELECT_COMMENT_AND_REACTION,
            get_data_number_day: values?.get_data_number_day,
          }
          try {
            setIsLoadingButton(true)
            await createSource(newValue)
            // setIsGetMemberGroup(false)
            if (checkedLocation === -1) {
              history.push(`${ROUTER_PATH.FACEBOOK_DETAIL}/${groupId}`)
              message.success('Tạo chiến dịch thành công')
            }
            message.success('Thiết lập nguồn thành công')
            setShowAddSourceModal(false)
            form.resetFields()
            setSourceFacebook(0)
            setFacebookSourceTypeWithGroup(
              FACEBOOK_SOURCE_TYPE_WITH_GROUP.EMPTY
            )
            dispatch(handleDataKeywordManagement([]))
          } catch (error) {
            console.log(error)
          } finally {
            setIsLoadingButton(false)
            // dispatch(handleAddSource(newValue))
          }
        }
        break

      case TYPE_SOURCE_FACEBOOK.PAGE:
        const dataKeyword = postFilterKeywords.map((item: any) => ({
          keyword_id: item.keyword_id,
          keyword: item.keyword,
        }))
        newValue = {
          ...values,
          keywords: dataKeyword,
          group_id: groupId,
        }
        try {
          setIsLoadingButton(true)
          await createSource(newValue)
          dispatch(handleDataKeywordManagement([]))
          if (checkedLocation === -1) {
            history.push(`${ROUTER_PATH.FACEBOOK_DETAIL}/${groupId}`)
            message.success('Tạo chiến dịch thành công')
          }
          message.success('Thiết lập nguồn thành công')
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoadingButton(false)
          setSourceFacebook(0)
          setFacebookSourceTypeWithGroup(FACEBOOK_SOURCE_TYPE_WITH_GROUP.EMPTY)
          form.resetFields()
          setShowAddSourceModal(false)
        }
        break

      case TYPE_SOURCE_FACEBOOK.POST:
        newValue = {
          ...values,
          group_id: groupId,
        }
        try {
          setIsLoadingButton(true)
          await createSource(newValue)
          if (checkedLocation === -1) {
            history.push(`${ROUTER_PATH.FACEBOOK_DETAIL}/${groupId}`)
            message.success('Tạo chiến dịch thành công')
          }
          message.success('Thiết lập nguồn thành công')
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoadingButton(false)
          setSourceFacebook(0)
          setFacebookSourceTypeWithGroup(FACEBOOK_SOURCE_TYPE_WITH_GROUP.EMPTY)
          form.resetFields()
          setShowAddSourceModal(false)
        }
        break

      case TYPE_SOURCE_FACEBOOK.ADVERTISEMENT:
        newValue = {
          ...values,
          group_id: groupId,
        }
        try {
          setIsLoadingButton(true)
          await createSource(newValue)
          if (checkedLocation === -1) {
            history.push(`${ROUTER_PATH.FACEBOOK_DETAIL}/${groupId}`)
            message.success('Tạo chiến dịch thành công')
          }
          message.success('Thiết lập nguồn thành công')
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoadingButton(false)
          setSourceFacebook(0)
          setFacebookSourceTypeWithGroup(FACEBOOK_SOURCE_TYPE_WITH_GROUP.EMPTY)
          form.resetFields()
          setShowAddSourceModal(false)
        }
        break

      default:
        break
    }
  }

  return (
    <>
      <Modal
        title={R.strings().title_modal_add_source}
        visible={show}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <Form
          name="sourceModal"
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item label="Nguồn facebook" name="type_group">
            <Select
              placeholder="Chọn nguồn"
              onChange={(value: number) => {
                setSourceFacebook(value)
              }}
            >
              <Option value={TYPE_SOURCE_FACEBOOK.GROUP}>
                {R.strings().options_group}
              </Option>
              <Option value={TYPE_SOURCE_FACEBOOK.PAGE}>
                {R.strings().options_page}
              </Option>
              <Option value={TYPE_SOURCE_FACEBOOK.POST}>
                {R.strings().options_post}
              </Option>
            </Select>
          </Form.Item>

          {contentSourceFacebook()}

          <Form.Item
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                message: R.strings().validate_get_data_number_day,
                validator: async (_, day) => {
                  if (!day || day < 1) {
                    return Promise.reject(new Error('Vui lòng nhập số dương!'))
                  }
                },
                required: true,
                whitespace: true,
              },
            ]}
            label={R.strings().label_get_data_number_day}
            name="get_data_number_day"
          >
            <InputNumberStyle
              min={1}
              max={100}
              type={'number'}
              placeholder={R.strings().placeholder_get_data_number_day}
            />
          </Form.Item>

          <ButtonBottomModal
            text={
              facebookSourceTypeWithGroup ===
              FACEBOOK_SOURCE_TYPE_WITH_GROUP.FILTER_BY_GROUP
                ? R.strings().action_next
                : R.strings().action_save
            }
            onCancel={handleCancel}
            isLoadingButton={isLoadingButton}
          />
        </Form>
      </Modal>
    </>
  )
}
