import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, message, PageHeader, Row } from 'antd'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { saveAs } from 'file-saver'

import { useExportExcelFileWithSocket } from 'hooks/useExportExcelFileWithSocket'
import AddAllCustomerToGroupModal from '../components/AddAllCustomerToGroupModal'
import AddCustomerToGroupModal from '../components/AddCustomerToGroupModal'
import DebounceSearchSelect from 'common/components/DebounceSearchSelect'
import { TableAntdStyle } from 'features/styles/Styles'
import { Wrapper } from 'common/components/Screens'
import R from 'assets'
import {
  filterName,
  filterPhone,
  filterProfession,
  filterProvince,
  filterWebsite,
  getListGoogleCustomer,
  handleExportExcelGoogleMap,
} from './GoogleApi'

export default function Google() {
  const [selectedRowList, setSelectedRowList] = useState<Array<any>>([])
  const [
    showAddCustomerToGroupModal,
    setShowAddCustomerToGroupModal,
  ] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any[]>([])

  const [payloadConvertAllCustomer, setPayloadConvertAllCustomer] = useState<
    Record<string, any>
  >({})
  const [
    showAddAllCustomerToGroupModal,
    setShowAddAllCustomerToGroupModal,
  ] = useState<boolean>(false)
  const [filter, setFilter] = useState<Record<string, string[]>>({
    profession: [],
    search: [],
    phone: [],
    website: [],
    province: [],
  })
  const [paging, setPaging] = useState<Record<string, number>>({
    total: 0,
    page: 1,
    limit: 12,
  })
  const [loading, setLoading] = useState<boolean>(true)

  const columns = [
    {
      width: '15%',
      title: 'Tên doanh nghiệp',
      dataIndex: 'ten',
      key: 'ten',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'dien_thoai',
      key: 'dien_thoai',
    },
    {
      title: 'Ngành nghề',
      dataIndex: 'nganh_nghe',
      key: 'nganh_nghe',
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      render: (website: string) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            String(website).includes('https://')
              ? website
              : `https://${website}`
          }
          onClick={e => e.stopPropagation()}
        >
          {`${website}`}
        </a>
      ),
    },
    {
      width: '20%',
      title: 'Địa chỉ',
      dataIndex: 'dia_chi',
      key: 'dia_chi',
    },

    {
      width: '15%',
      title: 'Tỉnh thành',
      dataIndex: 'tinh_thanh',
      key: 'tinh_thanh',
    },
    {
      width: '10%',
      title: 'Lượt hiển thị',
      dataIndex: 'luot_hien_thi_hang_ngay',
      key: 'luot_hien_thi_hang_ngay',
    },
  ]

  const handleConvertAll = () => {
    const { search, phone, website, profession, province } = filter
    setPayloadConvertAllCustomer({
      search,
      phone,
      website,
      profession,
      province,
    })
    setShowAddAllCustomerToGroupModal(true)
  }

  const handleChangeFilterName = (search: string[]) => {
    console.log(search)
    setFilter(prevFilter => ({
      ...prevFilter,
      search: search,
    }))
    setPaging({ ...paging, page: 1 })
  }

  const handleChangeFilterProfession = (professions: string[]) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      profession: professions,
    }))
    setPaging({ ...paging, page: 1 })
  }

  const handleChangeFilterWebsite = (websites: string[]) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      website: websites,
    }))
    setPaging({ ...paging, page: 1 })
  }

  const handleChangeFilterPhone = (phones: string[]) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      phone: phones,
    }))
    setPaging({ ...paging, page: 1 })
  }

  const handleChangeFilterProvince = (province: string[]) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      province: province,
    }))
    setPaging({ ...paging, page: 1 })
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      console.log({ selectedRows })

      setSelectedRowList(
        selectedRows.map((row: any) => ({
          id: row.id,
          name: row.ten,
          phone: row.dien_thoai,
          email: '',
        }))
      )
    },
    preserveSelectedRowKeys: true,
  }

  const initGoogleList = async (payload: any) => {
    setLoading(true)
    try {
      const initGoogleListResponse = await getListGoogleCustomer(payload)
      const listCustomerAddedKeys = initGoogleListResponse.data.map(
        (item: any) => ({ ...item, key: item.id })
      )
      setDataSource(listCustomerAddedKeys)
      setPaging(prevPaging => ({
        ...prevPaging,
        total: initGoogleListResponse.paging.totalItemCount,
      }))
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setLoading(false)
    }
  }

  const debouncedGetListGoogleCustomer = useCallback(
    debounce(payload => {
      initGoogleList(payload)
    }, 1000),
    []
  )

  useEffect(() => {
    const query = JSON.stringify(filter)
    debouncedGetListGoogleCustomer({ query, page: paging.page })
  }, [filter, paging.page])

  //export excel file - full customer
  // const userState = useSelector(
  //   (redux: Record<string, any>) => redux.authReducer?.userInfo
  // )
  // const { exportExcel, isLoading } = useExportExcelFileWithSocket({
  //   callback: () =>
  //     handleExportExcelGoogleMap({
  //       query: JSON.stringify(filter),
  //       page: paging.page,
  //     }),
  //   channel: `export_excel_google_${userState?.id}`,
  //   fileName: 'Du_lieu_google_map',
  // })

  //export excel file - limit customer
  const [
    isLoadingButtonExportExcel,
    setIsLoadingButtonExportExcel,
  ] = useState<boolean>(false)
  const exportExcel = async () => {
    try {
      const query = JSON.stringify(filter)
      setIsLoadingButtonExportExcel(true)
      message.success(
        R.strings().message_request_has_been_received +
          ' ' +
          R.strings().message_please_wait_a_few_minutes
      )
      const response = await handleExportExcelGoogleMap({
        query,
        page: paging.page,
      })

      saveAs(response.data, `Du_lieu_google_map.xlsx`)
    } catch (error) {
      console.log('Error - Export excel file - google map: ', { error })
      message.error(
        R.strings().message_an_error_occurred +
          ' ' +
          R.strings().message_please_try_again
      )
    } finally {
      setIsLoadingButtonExportExcel(false)
    }
  }

  return (
    <Fragment>
      <Wrapper>
        <PageHeader
          style={{ minHeight: '3%' }}
          className="site-page-header"
          title={R.strings().title_header_data_google_map}
        />
        <div style={{ marginTop: '10px' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={4}>
              <DebounceSearchSelect
                mode="multiple"
                fetchOptions={filterName}
                setChangeFilter={handleChangeFilterName}
                placeholder="Nhập tên"
              />
            </Col>
            <Col xs={24} md={5}>
              <DebounceSearchSelect
                mode="multiple"
                fetchOptions={filterProfession}
                setChangeFilter={handleChangeFilterProfession}
                placeholder="Nhập ngành nghề"
              />
            </Col>
            <Col xs={24} md={5}>
              <DebounceSearchSelect
                mode="multiple"
                fetchOptions={filterWebsite}
                setChangeFilter={handleChangeFilterWebsite}
                placeholder="Nhập website"
              />
            </Col>
            <Col xs={24} md={5}>
              <DebounceSearchSelect
                mode="multiple"
                fetchOptions={filterPhone}
                setChangeFilter={handleChangeFilterPhone}
                placeholder="Nhập số điện thoại"
              />
            </Col>
            <Col xs={24} md={5}>
              <DebounceSearchSelect
                mode="multiple"
                fetchOptions={filterProvince}
                setChangeFilter={handleChangeFilterProvince}
                placeholder="Nhập tỉnh thành"
              />
            </Col>
          </Row>

          <div style={{ float: 'right', margin: '10px 0px' }}>
            <Button type="primary" onClick={() => handleConvertAll()}>
              {R.strings().action_select_all}
            </Button>

            <Button
              type="primary"
              style={{ marginLeft: '5px' }}
              onClick={() => setShowAddCustomerToGroupModal(true)}
            >
              <PlusCircleOutlined /> {R.strings().action_add_customer_group}
            </Button>

            <Button
              type="primary"
              style={{
                marginLeft: '5px',
                backgroundColor: 'green',
                border: 'none',
                color: 'white',
              }}
              disabled={isLoadingButtonExportExcel}
              onClick={exportExcel}
            >
              {R.strings().action_export_excel_file}
            </Button>
          </div>

          <div>
            <TableAntdStyle
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
                // y: 'calc(100vh - 370px)',
              }}
              loading={loading}
              rowSelection={{ ...rowSelection }}
              dataSource={dataSource}
              columns={columns}
              pagination={{
                total: paging.total,
                defaultPageSize: paging.limit,
                showSizeChanger: false,
                onChange: page => setPaging({ ...paging, page: page }),
              }}
            />
          </div>
        </div>
      </Wrapper>

      <AddCustomerToGroupModal
        choosenConvertedFBList={selectedRowList}
        show={showAddCustomerToGroupModal}
        setShowAddCustomerToGroupModal={setShowAddCustomerToGroupModal}
      />

      {showAddAllCustomerToGroupModal && (
        <AddAllCustomerToGroupModal
          from="GOOGLE"
          payload={payloadConvertAllCustomer}
          total={paging?.total}
          visible={showAddAllCustomerToGroupModal}
          setVisible={setShowAddAllCustomerToGroupModal}
        />
      )}
    </Fragment>
  )
}
