import { Form, Input, message, Modal, Select } from 'antd'
import R from 'assets'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { IS_ACTIVE } from 'utils/constants'
import { createKeyword, updateKeyword } from '../FacebookApi'
import { handleKeyword } from '../FacebookSlice'

const { Option } = Select

type Props = {
  show: boolean
  setShowAddEditKeywordModal: (show: boolean) => void
  setShowKeywordManagementModal: (show: boolean) => void
}

export default function AddEditKeywordModal({
  show,
  setShowAddEditKeywordModal,
  setShowKeywordManagementModal,
}: Props) {
  const dispatch = useDispatch()

  const { keyword } = useSelector((state: any) => state.facebookReducer)

  const [form] = Form.useForm()

  const handleCancel = () => {
    setShowAddEditKeywordModal(false)
    form.resetFields()
    dispatch(handleKeyword({ id: 0, content: '', isActive: 0 }))
    setShowKeywordManagementModal(true)
  }

  const onFinish = async (value: { keyword: string; status: number }) => {
    try {
      if (keyword.id === 0) {
        const response = await createKeyword({ content: value.keyword.trim() })
        message.success(`Thêm mới thành công từ khoá ${response?.data.content}`)
        setShowAddEditKeywordModal(false)
        form.resetFields()
        setShowKeywordManagementModal(true)
      } else {
        await updateKeyword({
          keyword_id: keyword.id,
          content: value.keyword,
          is_active: value.status,
        })
        message.success('Sửa thành công từ khoá')
        setShowAddEditKeywordModal(false)
        form.resetFields()
        setShowKeywordManagementModal(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function convertDataToFrom(keyword: any) {
    if (keyword.id === 0) {
      return {
        keyword: '',
      }
    } else {
      return {
        ...keyword,
        keyword: keyword.content,
        status: keyword.isActive,
      }
    }
  }

  useEffect(() => {
    const initialValuesKeywordModal = convertDataToFrom(keyword)
    form.setFieldsValue(initialValuesKeywordModal)
  }, [keyword])

  return (
    <Modal
      title={
        keyword.id !== 0
          ? R.strings().title_modal_edit_keyword
          : R.strings().title_modal_add_keyword
      }
      visible={show}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
    >
      <Form
        name="add_keyword"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        // initialValues={initialValuesKeywordModal}
      >
        <Form.Item
          label="Nhập từ khoá"
          name="keyword"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Vui lòng nhập từ khoá!',
            },
          ]}
        >
          <Input placeholder="Nhập từ khoá" />
        </Form.Item>

        {keyword.id !== 0 ? (
          <Form.Item label="Trạng thái" name="status">
            <Select placeholder="Trạng thái" onChange={(value: number) => {}}>
              <Option value={IS_ACTIVE.ACTIVE}>Đang hoạt động</Option>
              <Option value={IS_ACTIVE.DEACTIVE}>Tạm dừng hoạt động</Option>
            </Select>
          </Form.Item>
        ) : (
          <></>
        )}

        <ButtonBottomModal text="Lưu" onCancel={handleCancel} />
      </Form>
    </Modal>
  )
}
