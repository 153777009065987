import React, { useEffect, useState } from 'react'
import { getFacebookBranch, getListKeyword } from '../FacebookApi'
import AddEditKeywordModal from './AddEditKeywordModal'
import AddSourceModal from './AddSourceModal'
import EditKeywordSourceModal from './EditKeywordSourceModal'
import KeywordManagementModal from './KeywordManagementModal'
import SelectGroupModal from './SelectGroupModal'

type Props = {
  group_id?: number
  showAddSourceModal: boolean
  setShowAddSourceModal: (show: boolean) => void
  showKeywordManagementModal: boolean
  setShowKeywordManagementModal: (show: boolean) => void
  showSelectGroupModal: boolean
  setShowSelectGroupModal: (show: boolean) => void
  showAddEditKeywordModal: boolean
  setShowAddEditKeywordModal: (show: boolean) => void
  showEditKeywordSourceModal?: boolean
  setShowEditKeywordSourceModal?: (show: boolean) => void
  getDataCustomerResource?: () => void
}

export default function SetSource({
  group_id,
  showAddSourceModal,
  setShowAddSourceModal,
  showKeywordManagementModal,
  setShowKeywordManagementModal,
  showSelectGroupModal,
  setShowSelectGroupModal,
  showAddEditKeywordModal,
  setShowAddEditKeywordModal,
  showEditKeywordSourceModal,
  setShowEditKeywordSourceModal,
  getDataCustomerResource,
}: Props) {
  const getDataFacebookBranch = async (param: any) =>
    await getFacebookBranch(param)

  const getDataKeywordManagement = async (param: any) =>
    await getListKeyword(param)

  useEffect(() => {
    if (
      !showAddSourceModal &&
      !showSelectGroupModal &&
      !showEditKeywordSourceModal
    )
      getDataCustomerResource && getDataCustomerResource()
  }, [showAddSourceModal, showSelectGroupModal, showEditKeywordSourceModal])

  const [prevModal, setPrevModal] = useState<string>('')

  return (
    <>
      <AddSourceModal
        groupId={group_id}
        show={showAddSourceModal}
        setShowAddSourceModal={setShowAddSourceModal}
        setShowKeywordManagementModal={setShowKeywordManagementModal}
        setShowSelectGroupModal={setShowSelectGroupModal}
        //logic
        getDataFacebookBranch={getDataFacebookBranch}
        getDataKeywordManagement={getDataKeywordManagement}
        setPrevModal={setPrevModal}
      />

      <KeywordManagementModal
        show={showKeywordManagementModal}
        setShowKeywordManagementModal={setShowKeywordManagementModal}
        setShowAddSourceModal={setShowAddSourceModal}
        setShowAddEditKeywordModal={setShowAddEditKeywordModal}
        setShowEditKeywordSourceModal={setShowEditKeywordSourceModal}
        //logic
        getDataKeywordManagement={getDataKeywordManagement}
        prevModal={prevModal}
        setPrevModal={setPrevModal}
      />

      <SelectGroupModal
        show={showSelectGroupModal}
        setShowSelectGroupModal={setShowSelectGroupModal}
        setShowAddSourceModal={setShowAddSourceModal}
      />

      <AddEditKeywordModal
        show={showAddEditKeywordModal}
        setShowAddEditKeywordModal={setShowAddEditKeywordModal}
        setShowKeywordManagementModal={setShowKeywordManagementModal}
      />

      <EditKeywordSourceModal
        show={showEditKeywordSourceModal || false}
        setShowEditKeywordSourceModal={setShowEditKeywordSourceModal}
        setShowKeywordManagementModal={setShowKeywordManagementModal}
        getDataKeywordManagement={getDataKeywordManagement}
        setPrevModal={setPrevModal}
      />
    </>
  )
}
