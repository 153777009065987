import { SettingOutlined } from '@ant-design/icons'
import { Button } from 'antd'

type ButtonSettingProps = {
  onClickButton?: (event: any) => void
  text?: string
  icon?: any
  loading?: boolean
  size?: any
  disableButton?: boolean
}

const ButtonSetting = ({
  text,
  onClickButton,
  icon,
  loading,
  size,
  disableButton,
}: ButtonSettingProps) => {
  return (
    <Button
      style={{
        minWidth: '45px',
        fontWeight: 'bold',
        borderRadius: '5px',
      }}
      type={text ? 'primary' : 'text'}
      onClick={(event: any) => {
        onClickButton && onClickButton(event)
      }}
      disabled={disableButton}
    >
      {icon ? icon : <SettingOutlined />}
      {text}
    </Button>
  )
}

export default ButtonSetting
