import { CaretRightOutlined, InboxOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'
import { Collapse, message, Upload } from 'antd'
import styled from 'styled-components'
import R from 'assets'

import { getUserInfoAction } from 'features/auth/AuthSlice'
import { createFormData } from 'utils/createFormData'
import { ButtonAdd } from 'common/components/Buttons'
import { ApiClient } from 'services/ApiService'

const { Panel } = Collapse
const { Dragger } = Upload

const StyleExpandMenu = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyleTokenCount = styled.div`
  margin-left: 10px;
  font-weight: 700;
`

const StyleDragger = styled(Dragger)`
  &&& {
    .ant-upload {
      padding: 0px;
    }

    .ant-upload-text,
    .ant-upload-drag-icon {
      font-size: 12px;
      margin: 8px 0px;
    }

    margin: 10px;
    width: 90%;
  }
`

const StyleButtonSpace = styled.div`
  margin: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const uploadTokenFile = (payload: any) =>
  ApiClient.post('/token/uploads', payload)

const acceptFileFormat = '.txt'

function MenuExpand() {
  const dispatch = useDispatch()

  const userInfo = useSelector(
    (redux: Record<string, any>) => redux.authReducer.userInfo
  )

  const [tokenFile, setTokenFile] = useState<File | undefined>(undefined)

  const propsDragger: Record<string, any> = {
    name: 'file',
    multiple: false,
    fileList: tokenFile && [tokenFile],
    maxCount: 1,
    accept: acceptFileFormat,
    onRemove: () => setTokenFile(undefined),
    customRequest: async (options: Record<string, any>) => {
      const { onSuccess, file } = options
      setTokenFile(file)
      onSuccess('ok')
    },
  }

  const handleTokenUpload = async () => {
    try {
      const formData = createFormData({ file: tokenFile })
      await uploadTokenFile(formData)
      message.success(R.strings().dialog_success)
      setTokenFile(undefined)
      dispatch(getUserInfoAction())
    } catch (error) {
      console.log('Error upload token file: ', { error })
    }
  }

  return (
    <>
      {userInfo &&
        Object.prototype.hasOwnProperty.call(userInfo, 'totalToken') && (
          <StyleExpandMenu>
            <StyleTokenCount>
              {R.strings().tokens_remaining + ': ' + userInfo.totalToken}
            </StyleTokenCount>

            <hr />

            <Collapse
              bordered={false}
              defaultActiveKey={[]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="site-collapse-custom-collapse"
            >
              <Panel
                header={R.strings().update_tokens}
                key="1"
                className="site-collapse-custom-panel"
              >
                <StyleDragger {...propsDragger}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {R.strings().click_or_drag_the_token_file_here}
                  </p>
                </StyleDragger>

                <StyleButtonSpace>
                  <ButtonAdd
                    disabled={!tokenFile}
                    text={R.strings().action_update_tokens}
                    onClickButton={handleTokenUpload}
                  />
                </StyleButtonSpace>
              </Panel>
            </Collapse>
          </StyleExpandMenu>
        )}
    </>
  )
}

export default MenuExpand
