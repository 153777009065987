import { Breadcrumb, Button, Row, Table, Tabs } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import { colors } from 'common/theme'
import { Link } from 'react-router-dom'
import styledComponents from 'styled-components'
import { number } from 'utils/ruleForm'

export const TableAntdStyle = styledComponents(Table)`
  .ant-table-row .ant-table-cell {
    padding-top: 7px;
    padding-bottom: 3px;
  }

  .ant-table-expanded-row .ant-table-cell {
    padding: 0;
  }

  .ant-table-expanded-row .ant-table-cell {
    padding: 0;
  }
  .ant-table-thead > tr > th {
    padding:2px 10px;
  }
`

export const ButtonAntd = styledComponents(Button)`
`

export const NumberLine = styledComponents.span`
display: -webkit-box;
/* max-height: 4.2rem; */
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
white-space: normal;
line-height: 1.6rem;
-webkit-line-clamp:1;
  `

export const BreadcrumbStyle = styledComponents(Breadcrumb)`
  padding-bottom: 0px;
  margin-bottom:10px;
  text-decoration: underline;
  `

export const TabsStyle = styledComponents(Tabs)`
  .ant-tabs > .ant-tabs-nav{
    padding-inline:0px
  }
  `

export const HeaderStyle = styledComponents(Header)`
    display: flex;
    height: var(--header-height);
    align-items: center;
    background-color:${colors.orange};
    justify-content: space-between;
  `

export const RowStyle = styledComponents(Row)`
  display: flex,
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 8px 0px
  `

const itemDataCrawStatusWidthHeight = '8px'
export const pendingColor = '#e5f055'
export const successColor = '#26b426'
export const failColor = '#d44b4b'

export const DataCrawStatusStyle = styledComponents.div`
  display: flex;

  position: absolute;
  top: 4px;
  right: 6px;
`

interface IItemDataCrawStatusStyleProps {
  bgColor: string
}

export const ItemDataCrawStatusStyle = styledComponents.div`
  width: ${itemDataCrawStatusWidthHeight};
  height: ${itemDataCrawStatusWidthHeight};
  border-radius: 50%;

  margin-left: 5px;

  opacity: 0.75;
  background-color: ${(props: IItemDataCrawStatusStyleProps) => props.bgColor};
`
