import { message } from 'antd'
import { create } from 'apisauce'
import { ENVIRONMENT, SESSION_KEY } from 'common/config'
import Cookie from 'js-cookie'
import queryString from 'query-string'
import { API_STATUS } from 'utils/constants'

const BASE_URL_DEV = ENVIRONMENT.BASE_URL

const createAPI = () => {
  const APIInstant = create({
    baseURL: BASE_URL_DEV,
    // timeout: 20000,
    headers: { 'Content-Type': 'application/json' },
  })

  // if (localStorage.getItem('token')) {
  APIInstant.setHeader('token', Cookie.get(SESSION_KEY.SESSION) || '')
  // }
  APIInstant.axiosInstance.interceptors.request.use(
    async config => {
      config.headers.token = Cookie.get(SESSION_KEY.SESSION)
      return config
    },
    error => Promise.reject(error)
  )
  APIInstant.axiosInstance.interceptors.response.use(
    response => {
      const data = response.data
      if (
        (data && data.code === API_STATUS.RE_LOGIN) ||
        data.code === API_STATUS.NOT_FOUND
      ) {
        Cookie.set(SESSION_KEY.SESSION, '')
        localStorage.setItem('token', '')
        // window.location.reload()
        // const store = require('../redux/store').default
        //   store.dispatch({ type: LOGOUT })
        //   NavigationUtil.navigate(SCREEN_ROUTER_APP.HOME)
        //   showMessages(R.strings().notification, R.strings().re_login)
        // }
      } else if (data && data.status !== 1) {
        // swal({
        //   title: R.strings().fail_request,
        //   text: data?.msg || R.strings().error_network,
        //   icon: 'error',
        // })
      }
      //   showMessages(R.strings().notification, data.msg)
      return response
    },
    error => {
      console.log('error: ', error.message)
      message.error(error.message)
    }
  )
  return APIInstant
}
const axiosInstance = createAPI()

/* Support function */
function handleResult(api: any) {
  return api.then(
    (res: { data: { status: number; code: number; msg: string } }) => {
      if (res?.data?.status !== 1) {
        // message.error(`Đã có lỗi xảy ra, vui lòng thử lại`)
        message.error(res.data.msg)
        return Promise.reject(res?.data)
      }
      return Promise.resolve(res?.data)
    }
  )
}

function parseUrl(url: string, query: any) {
  return queryString.stringifyUrl({ url: url, query })
}

export const ApiClient = {
  get: (url: string, payload?: any) =>
    handleResult(axiosInstance.get(parseUrl(url, payload))),
  post: (url: string, payload?: any) =>
    handleResult(axiosInstance.post(url, payload)),
  put: (url: string, payload?: any) =>
    handleResult(axiosInstance.put(url, payload)),
  path: (url: string, payload: any) =>
    handleResult(axiosInstance.patch(url, payload)),
  delete: (url: string, payload: any) =>
    handleResult(axiosInstance.delete(url, {}, { data: payload })),
}

export default axiosInstance
