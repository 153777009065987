import { ApiClient } from 'services/ApiService'

const tool: string = '/tool'

export const getListGroupCustomer = (
  payload: Record<'search' | 'page' | 'limit', string | number>
) =>
  ApiClient.get(
    `${tool}/listGroupCustomer?search=${payload.search}&page=${payload.page}&limit=${payload.limit}`
  )

export const getDetailListGroupCustomer = (
  payload: Record<'group_data_id' | 'page', string | number>
) =>
  ApiClient.get(
    `${tool}/groupCustomerDetail?group_data_id=${payload.group_data_id}&page=${payload.page}`
  )

export const exportExcelDetailOfGroupCustomer = (group_data_id: string) =>
  ApiClient.get(
    `${tool}/groupCustomerDetailExcel?group_data_id=${group_data_id}`
  )
