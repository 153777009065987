import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Input, PageHeader, Pagination, Row, Space, Table } from 'antd'
import R from 'assets'
import { ButtonDelete } from 'common/components/Buttons'
import { Wrapper } from 'common/components/Screens'
import { ROUTER_PATH } from 'common/config'
import { deleteGroupCustomerAPI } from 'features/customer-resources/facebook/FacebookApi'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import _ from 'lodash'
import { getListGroupCustomer } from './setOfCustomerAPI'
import { ColumnsType } from 'antd/lib/table'
import { TableAntdStyle } from 'features/styles/Styles'

export default function SetOfCustomer() {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [dataSource, setDataSource] = useState<any>([])
  const [totalrecord, setTotalrecord] = useState<number>(0)
  const [deletedRecord, setDeletedRecord] = useState<boolean>(false)
  const limit = 12

  const columns: ColumnsType<Record<string, any>> = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id: number, record: any, index: number) => (
        <span>{(page - 1) * limit + index + 1}</span>
      ),
    },
    {
      title: 'Tên nhóm',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số lượng khách hàng',
      dataIndex: 'customer',
      key: 'customer',
      align: 'center',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (day: string) => <span>{moment(day).format('DD/MM/YYYY')}</span>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (action: string, record: any) => {
        return (
          <div style={{ textAlign: 'center', cursor: 'pointer' }}>
            <Space size="middle">
              <ButtonDelete
                //confirm
                title={R.strings().confirm_remove_campaign}
                cancelText={R.strings().action_back}
                okText={R.strings().action_remove}
                onConfirm={() => {
                  handleDeleteGroupCustomer(record.id)
                }}
                //button
                icon={<DeleteOutlined />}
              />
            </Space>
          </div>
        )
      },
    },
  ]

  const handleDeleteGroupCustomer = async (id: string) => {
    try {
      await deleteGroupCustomerAPI(id)
      setDeletedRecord(true)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setDeletedRecord(false)
    }
  }

  const handleChangePaging = (page: number) => {
    setPage(page)
  }

  const initSetOfCustomers = async (payload: any) => {
    setLoading(true)
    try {
      const listCustomerResponse = await getListGroupCustomer(payload)
      setDataSource(listCustomerResponse.data)
      setTotalrecord(listCustomerResponse.paging.totalItemCount)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setLoading(false)
    }
  }

  const debounceGetSetOfCustomers = useCallback(
    _.debounce(payload => {
      initSetOfCustomers(payload)
    }, 500),
    []
  )

  useEffect(() => {
    if (deletedRecord) {
      debounceGetSetOfCustomers({ page, search: searchQuery, limit })
    }
  }, [deletedRecord])

  useEffect(() => {
    debounceGetSetOfCustomers({ page, search: searchQuery, limit })
  }, [page, searchQuery])

  return (
    <>
      <Wrapper>
        <PageHeader
          className="site-page-header"
          title={R.strings().title_header_customer_group}
          extra={[
            <Input.Search
              enterButton={<SearchOutlined />}
              placeholder="Nhập tên nhóm khách hàng"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />,
          ]}
        />
      </Wrapper>

      <Wrapper>
        <TableAntdStyle
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          scroll={{ scrollToFirstRowOnChange: true, x: '1200px' }}
          onRow={(record: any, rowIndex) => ({
            onClick: () => {
              history.push({
                pathname: `${ROUTER_PATH.SET_OF_CUSTOMER_DETAIL}/${record.id}`,
                state: ROUTER_PATH.SET_OF_CUSTOMER,
              })
            },
          })}
          pagination={false}
        />
        <Row justify="end">
          {totalrecord > 0 && (
            <Pagination
              showSizeChanger={false}
              style={{ marginTop: '10px' }}
              onChange={handleChangePaging}
              current={page}
              pageSize={limit}
              total={totalrecord}
            />
          )}
        </Row>
      </Wrapper>
    </>
  )
}
