import {
  Button,
  Checkbox,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import R from 'assets'
import { ButtonFixed } from 'common/components/Buttons'
import Ellipsis from 'common/components/Ellipsis'
import { Wrapper } from 'common/components/Screens'
import { RowStyle, TableAntdStyle } from 'features/styles/Styles'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  DF_SELECT_MEMBER,
  optionSelect,
  LIST_CUSTOMER_TYPE,
  SELECT_MEMBER_TYPE,
} from 'utils/constants'
import AddCustomerToGroupModal from '../../components/AddCustomerToGroupModal'
import { clearData, getListFacebookCustomer } from '../FacebookApi'
interface Customer {
  data: Record<string, any>[]
  page: number
  total: number
  pageSize?: number
}

interface CustomerCommentListProps {
  isOnlyChooseCustomerWithPhone: boolean
  isFilterMemberCustomer: boolean
  page_id: string
  uniqueKey: number
  comment_id: number
}

const CustomerCommentList: FC<CustomerCommentListProps> = props => {
  const params: Record<string, string> = useParams()
  const { id } = params
  const [selectedRowListID, setSelectedRowListID] = useState<any>([])
  const [customer, setCustomer] = useState<Customer>({
    data: [],
    page: 1,
    total: 0,
  })

  const [transferedData, setTransferedData] = useState<boolean>(false)
  const limitPerPage = 12
  const [
    visibleModalTransferData,
    setVisibleModalTransferData,
  ] = useState<boolean>(false)
  const [
    isOnlyChooseCustomerWithPhone,
    setIsOnlyChooseCustomerWithPhone,
  ] = useState<boolean>(false)

  const [valueSelect, setValueSelect] = useState<number>(
    SELECT_MEMBER_TYPE.CUSTOMER
  )
  const [loadingTable, setLoadingTable] = useState<boolean>(true)
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)

  const columns: ColumnsType<Record<string, any>> = [
    {
      title: 'Tên người dùng cmt',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (name: string, record) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'black' }}
          href={`https://www.facebook.com/${record?.user_id}`}
        >
          {`${name}`}
        </a>
      ),
    },
    {
      title: 'SĐT người cmt',
      dataIndex: 'phone_convert',
      key: 'phone_convert',
    },
    {
      title: 'Link bài viết',
      dataIndex: 'post_id',
      key: 'post_id',
      render: (post_id: string) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.facebook.com/${post_id}`}
        >{`https://www.facebook.com/${post_id}`}</a>
      ),
    },
    {
      title: 'SĐT trong cmt',
      dataIndex: 'phone_comment',
      key: 'phone_comment',
    },
    {
      title: 'Nội dung cmt',
      dataIndex: 'message',
      key: 'message',
      width: '45%',
      render: (message: string) => <Ellipsis content={message}></Ellipsis>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      width: '120px',
      render: note => (note !== null ? 'Đã chuyển' : ''),
    },
  ]

  const getCustomerList = async () => {
    setLoadingTable(true)
    let listCustomerResponse: any
    let listData: any
    let payload = {
      page_id: props.page_id,
      page: customer.page,
      group_id: id,
      phone: isOnlyChooseCustomerWithPhone ? 1 : 0,
      type: LIST_CUSTOMER_TYPE.TEAM_MEMBERS,
    }
    try {
      if (valueSelect === SELECT_MEMBER_TYPE.POST) {
        payload.type = LIST_CUSTOMER_TYPE.MEMBERS_COMMENT_ARTICLE
      }

      listCustomerResponse = await getListFacebookCustomer(payload)
      listData = listCustomerResponse.data

      const addedKeyListCustomers = listData?.map(
        (cust: any, index: number) => ({
          ...cust,
          key: cust.user_id,
        })
      )

      if (isSelectAll) {
        setSelectedRowListID(
          listData?.map((row: any) => ({
            key: row?.user_id,
            id: row?.user_id,
            name: row.user_name,
            phone: row.phone,
            type: row.type,
            user_id: row?.user_id,
          }))
        )
      }
      setCustomer(prevCustomer => ({
        ...prevCustomer,
        data: addedKeyListCustomers,
        total: listCustomerResponse.paging?.totalItemCount,
        pageSize: listCustomerResponse?.paging.limit,
      }))
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setLoadingTable(false)
      // setTransferedData(false)
    }
  }

  const transferData = async () => {
    setVisibleModalTransferData(true)
  }

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      if (isSelectAll && selectedRowKeys?.length === customer?.pageSize) {
        setIsSelectAll(true)
      } else {
        setIsSelectAll(false)
      }
      setSelectedRowListID(
        selectedRows.map((row: any) => ({
          id: row.id,
          name: row.user_name,
          phone: row.phone_convert,
          type: row.type,
          key: row.key,
        }))
      )
    },
  }

  const handleClearData = async () => {
    try {
      const clearDataResponse = await clearData(props.page_id)
      message.success(clearDataResponse.msg)
      getCustomerList()
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  useEffect(() => {
    if (transferedData) {
      getCustomerList()
    }
  }, [transferedData])

  useEffect(() => {
    getCustomerList()
  }, [customer.page, isOnlyChooseCustomerWithPhone, valueSelect])

  //Chọn tất cả
  const handleSelectAll = useCallback(() => {
    setIsSelectAll(!isSelectAll)
    if (!isSelectAll) {
      setSelectedRowListID(
        customer.data?.map((row: any) => ({
          id: row.id,
          name: row.user_name,
          phone: row.phone,
          type: row.type,
          user_id: row?.user_id,
          key: row.key,
        }))
      )
    } else {
      setSelectedRowListID([])
    }
  }, [isSelectAll, customer])

  //lọc theo số đt
  const handleFilterPhone = useCallback(() => {
    setIsOnlyChooseCustomerWithPhone(!isOnlyChooseCustomerWithPhone)
  }, [isOnlyChooseCustomerWithPhone])

  const handleChangeSelect = (value: number | string) => {
    setValueSelect(Number(value))
  }

  return (
    <Wrapper>
      <Row justify="space-between" align="middle">
        <Row style={{}}>{customer?.total} Khách hàng</Row>
        <div>
          <Space>
            <Popconfirm
              placement="bottomRight"
              title="Bạn có thật sự muốn clear dữ liệu?"
              okText="Xóa"
              cancelText="Hủy"
              onConfirm={handleClearData}
              disabled={!customer.total}
            >
              <Button
                style={{
                  backgroundColor: 'red',
                  border: 'none',
                  color: 'white',
                }}
                disabled={!customer.total}
              >
                Clear dữ liệu
              </Button>
            </Popconfirm>

            <ButtonFixed
              text="Chuyển dữ liệu"
              style={{
                backgroundColor: '#0bcb0b',
                border: 'none',
                color: 'white',
                fontWeight: '500',
              }}
              disable={!selectedRowListID?.length}
              onClickButton={transferData}
            />
          </Space>
        </div>
      </Row>

      <RowStyle style={{ marginTop: 5 }}>
        <div>
          <Checkbox checked={isSelectAll} onChange={handleSelectAll}>
            {R.strings().select_all}
          </Checkbox>
        </div>

        <Row align="middle">
          <Checkbox
            style={{ marginRight: 5 }}
            checked={isOnlyChooseCustomerWithPhone}
            onChange={handleFilterPhone}
          >
            {R.strings().filter_phone}
          </Checkbox>

          <Select
            value={DF_SELECT_MEMBER[valueSelect]?.title}
            onChange={handleChangeSelect}
            style={{ width: 200 }}
            options={optionSelect}
          />
        </Row>
      </RowStyle>

      <div style={{ marginTop: 10 }}>
        <TableAntdStyle
          loading={loadingTable}
          bordered
          size="small"
          scroll={{
            x: 1920,
            scrollToFirstRowOnChange: true,
          }}
          rowSelection={{
            ...rowSelection,
            selectedRowKeys: selectedRowListID?.map(
              ({ key }: { key: string }) => key
            ),
          }}
          dataSource={customer.data}
          columns={columns}
          pagination={false}
        />
      </div>
      <Row justify="end">
        {customer.total > 0 && (
          <Pagination
            style={{ margin: '10px 0px' }}
            total={customer.total}
            pageSize={12}
            showSizeChanger={false}
            onChange={e =>
              setCustomer(prevCustomer => ({ ...prevCustomer, page: e }))
            }
          ></Pagination>
        )}
      </Row>

      {visibleModalTransferData && (
        <AddCustomerToGroupModal
          transferAll={isSelectAll}
          filter={valueSelect}
          group_id={id}
          setTransferedData={setTransferedData}
          page_id={props.page_id}
          show={visibleModalTransferData}
          choosenConvertedFBList={selectedRowListID}
          setShowAddCustomerToGroupModal={setVisibleModalTransferData}
        />
      )}
    </Wrapper>
  )
}

export default CustomerCommentList
