import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import R from 'assets'
import LoadingProgress from 'common/components/LoadingProgress'
import { SESSION_KEY } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { REG_PHONE } from 'utils/constants'
import history from 'utils/history'
import { requestLogin } from './AuthApi'
// import './authStyle.css'
import styles from './authStyle.module.css'

// 0963455625/123456

//0971764969/123456Aa@

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    // sm: {
    //   span: 18,
    //   offset: 6,
    // },
  },
}

function Login(props: any) {
  const [isLoading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const resLogin = await requestLogin({
        user_name: values.username.trim(),
        password: values.password,
      })
      const listTabs = resLogin?.data?.listTab?.map(
        (item: any) => item.tab_name
      )
      Cookie.set(SESSION_KEY.SESSION, resLogin.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      })
      dispatch(getUserInfoAction())
      history.replace(`${listTabs[0]}`)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className={styles.limiter}>
      <div className={styles.containerLogin100}>
        <div className={styles.wrapLogin100}>
          <div className={styles.login100FormTitle}>
            <span className={styles.login100FormTitle1}>Đăng nhập</span>
          </div>

          <div className={styles.contentLoginForm}>
            <Form
              {...formItemLayout}
              form={form}
              name="normal_login"
              initialValues={{ username: null, password: null }}
              onFinish={onFinish}
              requiredMark={false}
              labelAlign={'left'}
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: R.strings().please_enter_phone },
                  { pattern: REG_PHONE, message: 'Số điện thoại không hợp lệ' },
                ]}
                label={R.strings().phone}
              >
                <Input placeholder={R.strings().phone} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: R.strings().please_enter_pass },
                ]}
                label={R.strings().password}
              >
                <Input.Password
                  // type="password"
                  placeholder={R.strings().password}
                  iconRender={visible =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <Form.Item
                style={{
                  textAlign: 'center',
                }}
                {...tailFormItemLayout}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  // className={styles.login_form_button}
                  style={{
                    backgroundColor: 'orange',
                    borderColor: 'orange',
                    fontSize: '16px',
                    maxWidth: '200px',
                    minWidth: '150px',
                    borderRadius: '4px',
                  }}
                >
                  {R.strings().login}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      {isLoading && <LoadingProgress />}
    </div>
  )
}

export default Login
