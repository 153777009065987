import { Button, Popconfirm } from 'antd'
import {
  CloseCircleOutlined,
  DeleteFilled,
  LeftCircleOutlined,
} from '@ant-design/icons'

type ButtonDeleteProps = {
  onConfirm: () => void
  onCancel?: () => void
  text?: string
  title: string
  icon?: any
  loading?: boolean
  okText?: any
  cancelText?: any
  onClickButton?: () => void
}

const ButtonDelete = ({
  text,
  icon,
  title,
  okText,
  cancelText,
  onConfirm,
  onCancel,
  onClickButton,
}: ButtonDeleteProps) => {
  return (
    <Popconfirm
      placement="bottomRight"
      title={title}
      onConfirm={(event: any) => {
        event.stopPropagation()
        onConfirm && onConfirm()
      }}
      onCancel={(event: any) => {
        event.stopPropagation()
        onCancel && onCancel()
      }}
      okText={
        okText ? (
          okText
        ) : (
          <span>
            <CloseCircleOutlined />
            &nbsp;Xóa
          </span>
        )
      }
      cancelText={
        cancelText ? (
          cancelText
        ) : (
          <span>
            <LeftCircleOutlined />
            &nbsp;Trở lại
          </span>
        )
      }
      okButtonProps={{
        type: 'ghost',
        danger: true,
      }}
      cancelButtonProps={{
        style: {
          minWidth: '70px',
          // fontWeight: 'bold',
          borderColor: 'black',
          color: 'black',
          borderRadius: '5px',
        },
      }}
    >
      {text ? (
        <Button
          style={{
            minWidth: '45px',
            fontWeight: 'bold',
            borderRadius: '5px',
          }}
          danger
          onClick={(event: any) => {
            event.stopPropagation()
            onClickButton && onClickButton()
          }}
        >
          {icon ? icon : <CloseCircleOutlined />}
          {text}
        </Button>
      ) : (
        <Button
          type="text"
          size="large"
          icon={<DeleteFilled />}
          style={{ color: 'red' }}
          onClick={(event: any) => {
            event.stopPropagation()
            onClickButton && onClickButton()
          }}
        />
      )}
    </Popconfirm>
  )
}

export default ButtonDelete
