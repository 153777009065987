export const createFormData = (dataObject: Record<string, any>) => {
  const formData = new FormData()

  const keys = Object.keys(dataObject)

  if (keys.length === 0) {
    return null
  }

  keys.forEach(key => {
    formData.append(key, dataObject[key])
  })

  return formData
}
