export const ROUTER_PATH = {
  HOME: '/',
  FACEBOOK: '/facebook',
  FACEBOOK_DETAIL: '/chi-tiet-chien-dich',
  GOOGLE: '/google',
  SET_OF_CUSTOMER: '/tap-khach-hang',
  SET_OF_CUSTOMER_DETAIL: '/chi-tiet-tap-khach-hang',
  BUSINESS_REGISTRATION: '/dang-ky-kinh-doanh',
  CREATE_CAMPAIGN: '/tao-chien-dich',
}
