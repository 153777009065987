import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Table } from 'antd'
import R from 'assets'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { TableAntdStyle } from 'features/styles/Styles'
import React, { useState } from 'react'

type Props = {
  show: boolean
  convertedFBList: Record<string, any>
  choosenConvertedFBList: Array<any>
  setShowResultConvertFacebookModal: (show: boolean) => void
  setShowConvertFacebookModal: (show: boolean) => void
  setChoosenConvertedFBList: (list: any[]) => void
  setShowAddCustomerToGroupModal: (status: boolean) => void
}

export default function ResultConvertFacebookModal({
  show,
  convertedFBList,
  choosenConvertedFBList,
  setShowResultConvertFacebookModal,
  setShowConvertFacebookModal,
  setChoosenConvertedFBList,
  setShowAddCustomerToGroupModal,
}: Props) {
  const [selectedRowList, setSelectedRowList] = useState<Array<any>>([])

  const handleCancel = () => {
    setShowResultConvertFacebookModal(false)
    setShowConvertFacebookModal(true)
  }

  const columns = [
    {
      title: 'Trang facebook',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.facebook.com/${record.facebook_id}`}
        >
          {`https://www.facebook.com/${record.facebook_id}`}
        </a>
      ),
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setChoosenConvertedFBList(
        selectedRows.map((row: any) => ({
          id: row.id,
          name: row.name,
          phone: row.phone,
        }))
      )
    },
  }

  return (
    <>
      <Modal
        title={R.strings().title_modal_convert_facebook}
        visible={show}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        <span>
          Convert thành công :
          <span style={{ color: 'red' }}>
            &nbsp; {convertedFBList.success.length}/
            {convertedFBList.total.length}
          </span>
        </span>
        <div style={{ marginBottom: '5px' }}>
          <TableAntdStyle
            size="small"
            scroll={{
              scrollToFirstRowOnChange: true,
            }}
            rowSelection={{ ...rowSelection }}
            dataSource={convertedFBList.success}
            columns={columns}
            pagination={false}
          />
        </div>
        <div>
          <Row gutter={16} justify="end">
            <Col>
              <Button
                style={{ fontWeight: 800, borderRadius: '5px', height: '35px' }}
                type="primary"
                danger
                onClick={handleCancel}
              >
                <CloseCircleOutlined />
                Huỷ
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                disabled={
                  convertedFBList.total.length === 0 ||
                  choosenConvertedFBList.length === 0
                }
                style={{
                  fontWeight: 'bold',
                  height: '35px',
                  borderRadius: '5px',
                }}
                onClick={() => {
                  setShowResultConvertFacebookModal(false)
                  setShowAddCustomerToGroupModal(true)
                }}
              >
                <CheckCircleOutlined /> Thêm dữ liệu
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}
