import { Typography } from 'antd'
import { useState } from 'react'

const Ellipsis = (prop: any) => {
  const [ellipsis, setEllipsis] = useState<boolean>(true)
  return (
    <Typography.Paragraph
      key={`${ellipsis}`}
      ellipsis={
        ellipsis
          ? {
              rows: 3,
              expandable: true,
              symbol: 'Xem thêm',
              onExpand: e => {
                setEllipsis(false)
                e.stopPropagation()
              },
            }
          : false
      }
    >
      {prop.content}
      {!ellipsis && (
        <>
          ...
          <a
            style={{ fontSize: '15px' }}
            onClick={e => {
              setEllipsis(true)
              e.stopPropagation()
            }}
          >
            Rút gọn
          </a>
        </>
      )}
    </Typography.Paragraph>
  )
}
export default Ellipsis
