import { Input, message, PageHeader } from 'antd'
import R from 'assets'
import { ButtonFixed } from 'common/components/Buttons'
import ButtonSetting from 'common/components/Buttons/ButtonSetting'
import { Wrapper } from 'common/components/Screens'
import { ROUTER_PATH } from 'common/config'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { GROUP_TYPE } from 'utils/constants'
import SetSource from './components/SetSource'
import { createCampaign } from './FacebookApi'

const styleButtonPageHeader = {
  backgroundColor: '#0bcb0b',
  border: 'none',
  color: 'white',
  minWidth: '150px',
  borderRadius: '5px',
  fontWeight: '700',
}

const styleButtonPageHeaderDisable = {
  backgroundColor: '#a6b5a6',
  border: 'none',
  color: 'white',
  minWidth: '150px',
  borderRadius: '5px',
  fontWeight: '700',
}
export default function CreateCampaign() {
  const history = useHistory()
  const [inputValue, setInputValue] = useState<string>('')
  // const [currentSelected, setCurrentSelected] = useState<any>({ id: -1 })
  const [showAddSourceModal, setShowAddSourceModal] = useState<boolean>(false)
  const [
    showKeywordManagementModal,
    setShowKeywordManagementModal,
  ] = useState<boolean>(false)
  const [showSelectGroupModal, setShowSelectGroupModal] = useState<boolean>(
    false
  )
  const [
    showAddEditKeywordModal,
    setShowAddEditKeywordModal,
  ] = useState<boolean>(false)

  const [groupId, setGroupId] = useState<number>(0)

  const handleInputCreateCampaign = (event: any) => {
    setInputValue(event.target.value)
  }

  const handleCreateCampaign = async () => {
    try {
      const payload = {
        name: inputValue,
        type: GROUP_TYPE.FACEBOOK,
      }
      const response = await createCampaign(payload)
      console.log(response)
      history.push(ROUTER_PATH.FACEBOOK)
      message.success(`Thêm mới thành công ${response?.data.name}`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Wrapper>
        <PageHeader
          className="site-page-header"
          title={R.strings().title_header_create_campaign}
          extra={[
            <ButtonFixed
              disable={inputValue.trim().length === 0 ? true : false}
              text={R.strings().action_save}
              style={
                inputValue.trim().length === 0
                  ? styleButtonPageHeaderDisable
                  : styleButtonPageHeader
              }
              onClickButton={handleCreateCampaign}
            />,
          ]}
        />
      </Wrapper>

      <Wrapper>
        <div style={{ display: 'flex' }}>
          <label style={{ width: '20%' }}>Tên chiến dịch:</label>
          <Input
            placeholder="Nhập tên chiến dịch"
            onChange={handleInputCreateCampaign}
          />
        </div>
      </Wrapper>

      <Wrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '5px',
          }}
        >
          <div style={{ fontWeight: 500, fontSize: '17px' }}>
            {R.strings().title_header_source_list}
          </div>

          <div>
            <ButtonSetting
              disableButton={inputValue.trim().length === 0 ? true : false}
              text={R.strings().action_source_setting}
              onClickButton={async () => {
                try {
                  const payload = {
                    name: inputValue,
                    type: GROUP_TYPE.FACEBOOK,
                  }
                  const response = await createCampaign(payload)
                  setGroupId(response.data.id)
                } catch (error) {
                  console.log(error)
                }
                setShowAddSourceModal(true)
              }}
            />
          </div>
        </div>

        <div>
          {/* <Table
            className="table-expanded-custom-no-image"
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
              // y: 'calc(100vh - 370px)',
            }}
            dataSource={dataSource}
            // loading={isLoading}
            columns={columns}
            expandedRowKeys={[currentSelected.id]}
            onRow={(r: any) => ({
              onClick: () => {
                if (currentSelected.id !== r.id) setCurrentSelected(r)
                else setCurrentSelected({ id: -1 })
                reactotron.log!(r)
              },
            })}
            expandable={
              {
                // expandedRowRender: (record: any) => (
                //   <PostList is_create_campaign={true} sub_group_id={1} />
                // ),
                // onExpand: (status: any, r: any) => {
                //   if (currentSelected.id !== r.id) setCurrentSelected(r)
                //   else setCurrentSelected({ id: -1 })
                //   reactotron.log!(r)
                // },
              }
            }
            // pagination={{
            //   ...paging,
            //   onChange: async (page, pageSize) => {
            //     setParams({ ...params, page })
            //   },
            // }}
          /> */}
        </div>
      </Wrapper>

      <SetSource
        group_id={groupId}
        showAddSourceModal={showAddSourceModal}
        setShowAddSourceModal={setShowAddSourceModal}
        showKeywordManagementModal={showKeywordManagementModal}
        setShowKeywordManagementModal={setShowKeywordManagementModal}
        showSelectGroupModal={showSelectGroupModal}
        setShowSelectGroupModal={setShowSelectGroupModal}
        showAddEditKeywordModal={showAddEditKeywordModal}
        setShowAddEditKeywordModal={setShowAddEditKeywordModal}
      />
    </>
  )
}
