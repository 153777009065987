import { HomeOutlined, ShopOutlined } from '@ant-design/icons'
import { Menu, Spin } from 'antd'
import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import { logoutAction } from 'features/auth/AuthSlice'
import React, { Key, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import history from 'utils/history'

const { SubMenu } = Menu

export default function RMenu() {
  const dispatch = useDispatch()
  const authState = useSelector((state: any) => state.authReducer)
  const listTabs = authState?.userInfo?.listTab?.map(
    (item: any) => item.tab_name
  )
  const [openKeys, setOpenKeys] = useState<Array<any>>(['customer_resources'])

  function handleClick(e: any) {
    if (e.key === 'logout') {
      dispatch(logoutAction())
    } else {
      history.push(e.key)
    }
  }

  function handleGetCurrentRouter() {
    return window.location.pathname
  }

  const rootSubMenuKeys = Object.values(ROUTER_PATH)

  const handleChangeMenu = (keys: Key[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubMenuKeys.indexOf(latestOpenKey as string) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const AdminMenu = () => {
    return (
      <Menu
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={e => handleChangeMenu(e)}
        mode="inline"
        selectedKeys={[handleGetCurrentRouter()]}
      >
        <SubMenu
          key="customer_resources"
          icon={<ShopOutlined />}
          title={R.strings().title_header_customer_resources}
        >
          {listTabs?.includes('facebook') && (
            <Menu.Item key={ROUTER_PATH.FACEBOOK} icon={<HomeOutlined />}>
              {R.strings().title_header_facebook}
            </Menu.Item>
          )}

          {listTabs?.includes('google') && (
            <Menu.Item key={ROUTER_PATH.GOOGLE} icon={<HomeOutlined />}>
              {R.strings().title_header_google}
            </Menu.Item>
          )}

          {listTabs?.includes('dang-ky-kinh-doanh') && (
            <Menu.Item
              key={ROUTER_PATH.BUSINESS_REGISTRATION}
              icon={<HomeOutlined />}
            >
              {R.strings().title_header_business_registration}
            </Menu.Item>
          )}
        </SubMenu>

        <Menu.Item
          key={ROUTER_PATH.SET_OF_CUSTOMER}
          icon={<HomeOutlined />}
          children={R.strings().title_header_set_of_customers}
        />
      </Menu>
    )
  }

  return (
    <Spin spinning={authState.dialogLoading}>
      <AdminMenu />
    </Spin>
  )
}
