import { Button, Descriptions, PageHeader, Pagination, Row, Spin } from 'antd'
import { Wrapper } from 'common/components/Screens'
import { ROUTER_PATH } from 'common/config'
import * as ExcelJS from 'exceljs'
import { TableAntdStyle } from 'features/styles/Styles'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  exportExcelDetailOfGroupCustomer,
  getDetailListGroupCustomer,
} from './setOfCustomerAPI'

export default function SetOfCustomerDetail() {
  const history = useHistory()
  const params: Record<string, string> = useParams()
  const { id } = params

  const [group, setGroup] = useState<Record<string, any>>({})
  const [page, setPage] = useState<number>(1)
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const columns = [
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: any) => {
        return name ? name : '--'
      },
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tỉnh/thành phố',
      dataIndex: 'province',
      key: 'province',
      render: (province: Record<string, any>) => <span>{province?.name}</span>,
    },
  ]

  const getDetailGroupCustomer = async () => {
    setLoadingPage(true)
    try {
      const detailGroupCustomerResponse = await getDetailListGroupCustomer({
        group_data_id: id,
        page,
      })

      setGroup(detailGroupCustomerResponse?.data)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setLoadingPage(false)
    }
  }

  const exportExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('1')
    worksheet.getRow(1).values = [
      'STT',
      'Tên khách hàng',
      'SĐT',
      'Email',
      'Tỉnh/ thành phố',
    ]

    worksheet.columns = [
      { key: 'stt', width: 8 },
      { key: 'name', width: 40 },
      { key: 'phone', width: 40 },
      { key: 'email', width: 40 },
      { key: 'province', width: 40 },
    ]
    setLoadingPage(true)
    try {
      const response = await exportExcelDetailOfGroupCustomer(id)
      response.data.map((callInfor: any, index: number) => {
        worksheet.addRow({
          stt: index + 1,
          name: callInfor.name ? callInfor.name : callInfor.phone,
          phone: callInfor.phone,
          email: callInfor.email,
          province: callInfor.customer_province_name,
        })
      })
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        FileSaver.saveAs(blob, `DS_KhachHang.xlsx`)
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setLoadingPage(false)
    }
  }

  const handleChangePage = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    getDetailGroupCustomer()
  }, [page])

  return (
    <Spin spinning={loadingPage}>
      <Wrapper>
        <PageHeader
          className="site-page-header"
          title={<span>{group?.detail?.name}</span>}
          onBack={() => history.push(ROUTER_PATH.SET_OF_CUSTOMER)}
          extra={[
            <Button
              type="primary"
              style={{
                marginLeft: '5px',
                backgroundColor: 'green',
                border: 'none',
              }}
              onClick={() => exportExcel()}
            >
              Xuất file excel
            </Button>,
          ]}
        />
      </Wrapper>

      <Wrapper>
        <Descriptions title="Thông tin chung">
          <Descriptions.Item label="Mô tả">
            {group?.detail?.description}
          </Descriptions.Item>
          <Descriptions.Item label="Ngày tạo">
            {moment(group?.detail?.created_at).format('DD/MM/YYYY')}
          </Descriptions.Item>
          <Descriptions.Item label="Khách hàng">
            {group?.paging?.totalItemCount} khách
          </Descriptions.Item>
        </Descriptions>
      </Wrapper>

      <Wrapper>
        <div
          style={{ marginBottom: '10px', fontSize: '16px', fontWeight: 700 }}
        >
          Danh sách khách hàng
        </div>
        <div>
          <TableAntdStyle
            columns={columns}
            dataSource={group?.listCustomer}
            scroll={{ scrollToFirstRowOnChange: true, x: '1200px' }}
            pagination={false}
          />
        </div>
        <Row justify="end">
          {group?.paging?.totalItemCount > 0 && (
            <Pagination
              style={{ marginTop: '10px' }}
              total={group?.paging?.totalItemCount}
              pageSize={12}
              onChange={handleChangePage}
            ></Pagination>
          )}
        </Row>
      </Wrapper>
    </Spin>
  )
}
